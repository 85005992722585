import React from "react";

function NotFound404() {
  return (
    <div className="container not-404">
      <h1 className="not_found">404 not found</h1>
      <p className="no_page">page not found</p>
    </div>
  );
}

export default NotFound404;
