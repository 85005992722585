import React from "react";
import { MetaTags } from "react-meta-tags";

export default function Terms() {
  return (
    <div className="textPage" >
      <MetaTags>
        <title>Terms and Conditions - Subscription Agreement</title>
        <meta
          name="description"
          content="The Terms & Conditions (hereinafter the “Agreement”) is a set of rules governing the legal relationship between the Foundation and the Users."
        />
        <meta
          name="keywords"
          content="contemporary, art, georgian, georgian arts, design, contemporary, modern,fine art,auctions,artists,georgian artists,online auctions,prints,artworks,buy art,photographs,buy art,artwork for sale,decorative art,Artwork made by the contemporary artists in the GeorgiansGeorgia contemporary art,art from georgia,Georgia's Contemporary Artists,contemporary artists from Georgia,Georgian artist works prices,where i cen see georgian artists?,"
        />
      </MetaTags>
      <div className="row">
        <div className='col-md-5'>
          <h1>Terms & Conditions</h1>
        </div>
        <div className='col-md-5'>
        <div className="text">
     
        <h1 align="center" style={{ textAlign: "center" }}>
         
         
              <span
                style={{
                  fontSize: "12pt",
                  fontFamily: '"Arial", sans-serif',
                  color: "black",
                }}
              >
                INTRODUCTION
              </span>
           
      
        </h1>
        <p className="MsoNormal">
          <b>
            <span
              style={{
                fontSize: "12pt",
                lineHeight: "107%",
                fontFamily: '"Arial", sans-serif',
                color: "black",
              }}
            >
              &nbsp;
            </span>
          </b>
        </p>
        <p className="MsoNormal" style={{ textAlign: "justify" }}>
          <span
            style={{
              fontSize: "12pt",
              lineHeight: "107%",
              fontFamily: '"Arial", sans-serif',
              color: "black",
            }}
          >
            Ria Bid is a fundraising online marketplace oriented towards buying
            and selling Georgian art that allows users to offer, sell and buy
            original works of art. The actual contract for sale is directly
            between the seller and buyer. The Foundation is not a party to the
            transaction, unless it is specifically indicated in the listing that
            the Foundation acts as a seller.
          </span>
        </p>
        <p className="MsoNormal" style={{ textAlign: "justify" }}>
          <span
            style={{
              fontSize: "12pt",
              lineHeight: "107%",
              fontFamily: '"Arial", sans-serif',
              color: "black",
            }}
          >
            &nbsp;Ria Bid features Georgia’s famous art salons, private
            collections, artists, sellers - all in one place. Served as a
            protection racket of RIA KEBURIA FOUNDATION NNLE, the online
            platform collects funds for the following reasons:
          </span>
        </p>
        <p
          className="MsoListParagraphCxSpFirst"
          style={{ textIndent: "-0.25in" }}
        >
          <span
            style={{
              fontSize: "12pt",
              lineHeight: "107%",
              fontFamily: "Symbol",
              color: "black",
            }}
          >
            -
            <span style={{ font: '7pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontSize: "12pt",
              lineHeight: "107%",
              fontFamily: '"Arial", sans-serif',
              color: "black",
            }}
          >
            establishing newer wider residency programs;
          </span>
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{ textIndent: "-0.25in" }}
        >
          <span
            style={{
              fontSize: "12pt",
              lineHeight: "107%",
              fontFamily: "Symbol",
              color: "black",
            }}
          >
            -
            <span style={{ font: '7pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontSize: "12pt",
              lineHeight: "107%",
              fontFamily: '"Arial", sans-serif',
              color: "black",
            }}
          >
            covering production costs of future works of art;
          </span>
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{ textIndent: "-0.25in" }}
        >
          <span
            style={{
              fontSize: "12pt",
              lineHeight: "107%",
              fontFamily: "Symbol",
              color: "black",
            }}
          >
            -
            <span style={{ font: '7pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontSize: "12pt",
              lineHeight: "107%",
              fontFamily: '"Arial", sans-serif',
              color: "black",
            }}
          >
            development of the academic programs, workshops, exchange programs;
          </span>
        </p>
        <p
          className="MsoListParagraphCxSpLast"
          style={{ textIndent: "-0.25in" }}
        >
          <span
            style={{
              fontSize: "12pt",
              lineHeight: "107%",
              fontFamily: "Symbol",
              color: "black",
            }}
          >
            -
            <span style={{ font: '7pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontSize: "12pt",
              lineHeight: "107%",
              fontFamily: '"Arial", sans-serif',
              color: "black",
            }}
          >
            setting up COVID 19 grant for artist support in the time of the
            ongoing pandemic.
          </span>
        </p>
        <p className="MsoNormal">
          <b>
            <span
              style={{
                fontSize: "12pt",
                lineHeight: "107%",
                fontFamily: '"Arial", sans-serif',
                color: "black",
              }}
            >
              &nbsp;
            </span>
          </b>
        </p>
        <p className="MsoNormal" style={{ textAlign: "justify" }}>
          <span
            style={{
              fontSize: "12pt",
              lineHeight: "107%",
              fontFamily: '"Arial", sans-serif',
              color: "black",
            }}
          >
            Any guidance the Foundation provides as part of the Services, such
            as pricing, shipping, listing, and sourcing is solely informational
            and you may decide to follow it or not. Additionally, while the
            Foundation may help facilitate the resolution of disputes through
            negotiations, the Foundation has no control over and does not
            guarantee: the existence, quality, safety, or legality of items
            listed on the Website; the truth or accuracy of users' content or
            listings; the ability of sellers to sell items; the ability of
            buyers to pay for items; or that a buyer or seller will actually
            complete a transaction or return an item.
          </span>
        </p>
        <p className="MsoNormal" style={{ textAlign: "justify" }}>
          <b>
            <span
              style={{
                fontSize: "12pt",
                lineHeight: "107%",
                fontFamily: '"Arial", sans-serif',
                color: "black",
              }}
            >
              PLEASE NOTE THAT YOUR USE OF AND ACCESS TO THE SERVICES ARE
              SUBJECT TO THE FOLLOWING AGREEMENT; IF YOU DO NOT AGREE TO ALL OF
              THE FOLLOWING, YOU MAY NOT USE OR ACCESS THE SERVICES IN ANY
              MANNER.
            </span>
          </b>
        </p>
        <p className="MsoNormal" style={{ textAlign: "justify" }}>
          <b>
            <span
              style={{
                fontSize: "12pt",
                lineHeight: "107%",
                fontFamily: '"Arial", sans-serif',
                color: "black",
              }}
            >
              &nbsp;
            </span>
          </b>
        </p>
        <h1 align="center" style={{ textAlign: "center" }}>
          <a name="_Toc73815232">
            <b>
              <span
                style={{
                  fontSize: "12pt",
                  lineHeight: "107%",
                  fontFamily: '"Arial", sans-serif',
                  color: "black",
                }}
              >
                TERMS &amp; CONDITIONS
              </span>
            </b>
          </a>
        </h1>
        <p className="MsoNormal">
          <span
            style={{
              fontSize: "12pt",
              lineHeight: "107%",
              fontFamily: '"Arial", sans-serif',
            }}
          >
            &nbsp;
          </span>
        </p>
        <p className="MsoNormal" style={{ textAlign: "justify" }}>
          <span
            style={{
              fontSize: "12pt",
              lineHeight: "107%",
              fontFamily: '"Arial", sans-serif',
              color: "black",
            }}
          >
            The Terms &amp; Conditions (hereinafter the&nbsp;“Agreement”) is a
            set of rules governing the legal relationship between the Foundation
            and the User, with the purpose of defining the terms of service
            provided by the Foundation to the User and other matters relevant to
            such legal relationship.
          </span>
        </p>
        <p className="MsoNormal" style={{ textAlign: "justify" }}>
          <span
            style={{
              fontSize: "12pt",
              lineHeight: "107%",
              fontFamily: '"Arial", sans-serif',
              color: "black",
            }}
          >
            &nbsp;
          </span>
        </p>
        <p
          className="MsoListParagraph"
          style={{
            marginLeft: "42.55pt",
            textAlign: "justify",
            textIndent: "-42.55pt",
          }}
        >
          <a name="_Toc73815233">
            <b>
              <span
                style={{
                  fontSize: "12pt",
                  lineHeight: "107%",
                  fontFamily: '"Arial", sans-serif',
                  color: "black",
                }}
              >
                1.
                <span style={{ font: '7pt "Times New Roman"' }}>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
              </span>
            </b>
            <b>
              <span
                style={{
                  fontSize: "12pt",
                  lineHeight: "107%",
                  fontFamily: '"Arial", sans-serif',
                  color: "black",
                }}
              >
                Definitions of the Terms
              </span>
            </b>
          </a>
        </p>
        <p className="MsoNormal" style={{ textAlign: "justify" }}>
          <span
            style={{
              fontSize: "12pt",
              lineHeight: "107%",
              fontFamily: '"Arial", sans-serif',
              color: "black",
            }}
          >
            Unless otherwise indicated in the Agreement or otherwise provided in
            its context, the following definitions shall have the following
            meaning:
          </span>
        </p>
        <p
          className="MsoListParagraphCxSpFirst"
          style={{
            marginTop: "0in",
            marginRight: "0in",
            marginBottom: "10pt",
            marginLeft: "42.55pt",
            textAlign: "justify",
            textIndent: "-42.55pt",
            lineHeight: "115%",
          }}
        >
          <span
            style={{
              fontSize: "12pt",
              lineHeight: "115%",
              fontFamily: '"Arial", sans-serif',
              color: "black",
            }}
          >
            1.1.
            <span style={{ font: '7pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <b>
            <span
              style={{
                fontSize: "12pt",
                lineHeight: "115%",
                fontFamily: '"Arial", sans-serif',
                color: "black",
              }}
            >
              Foundation
            </span>
          </b>
          <span
            style={{
              fontSize: "12pt",
              lineHeight: "115%",
              fontFamily: '"Arial", sans-serif',
              color: "black",
            }}
          >
            - Ria Keburia Foundation NNLE, a Non-Entrepreneurial
            (Non-Commercial) Legal Entity incorporated and existing under
            Georgian Law, Identification Number: 427737666; Legal Address:
            Georgia, Gurjaani region,&nbsp; Village Kachreti, Sangali;
          </span>
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            marginTop: "0in",
            marginRight: "0in",
            marginBottom: "10pt",
            marginLeft: "42.55pt",
            textAlign: "justify",
            textIndent: "-42.55pt",
            lineHeight: "115%",
          }}
        >
          <span
            style={{
              fontSize: "12pt",
              lineHeight: "115%",
              fontFamily: '"Arial", sans-serif',
              color: "black",
            }}
          >
            1.2.
            <span style={{ font: '7pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <b>
            <span
              style={{
                fontSize: "12pt",
                lineHeight: "115%",
                fontFamily: '"Arial", sans-serif',
                color: "black",
              }}
            >
              User
            </span>
          </b>
          <span
            style={{
              fontSize: "12pt",
              lineHeight: "115%",
              fontFamily: '"Arial", sans-serif',
              color: "black",
            }}
          >
            – Person (including Buyer, Seller, Artist and Gallery) who accesses,
            visits and/or uses the Service and person, entity, or digital engine
            of any kind that harvests, crawls, indexes, scrapes, spiders, or
            mines digital content by an automated or manual process or
            otherwise. "User" also includes administrator, executor, successor
            and assign of such Person;
          </span>
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            marginTop: "0in",
            marginRight: "0in",
            marginBottom: "10pt",
            marginLeft: "42.55pt",
            textAlign: "justify",
            textIndent: "-42.55pt",
            lineHeight: "115%",
          }}
        >
          <span
            style={{
              fontSize: "12pt",
              lineHeight: "115%",
              fontFamily: '"Arial", sans-serif',
              color: "black",
            }}
          >
            1.3.
            <span style={{ font: '7pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <b>
            <span
              style={{
                fontSize: "12pt",
                lineHeight: "115%",
                fontFamily: '"Arial", sans-serif',
                color: "black",
              }}
            >
              Website
            </span>
          </b>
          <span
            style={{
              fontSize: "12pt",
              lineHeight: "115%",
              fontFamily: '"Arial", sans-serif',
              color: "black",
            }}
          >
            – official website of Ria Bid -{" "}
          </span>
          <a href="http://www.riabid.ge">
            <span
              style={{
                fontSize: "12pt",
                lineHeight: "115%",
                fontFamily: '"Arial", sans-serif',
                color: "black",
              }}
            >
              www.riabid.ge
            </span>
          </a>
          <span
            style={{
              fontSize: "12pt",
              lineHeight: "115%",
              fontFamily: '"Arial", sans-serif',
              color: "black",
            }}
          >
            ;
          </span>
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            marginTop: "0in",
            marginRight: "0in",
            marginBottom: "10pt",
            marginLeft: "42.55pt",
            textAlign: "justify",
            textIndent: "-42.55pt",
            lineHeight: "115%",
          }}
        >
          <span
            style={{
              fontSize: "12pt",
              lineHeight: "115%",
              fontFamily: '"Arial", sans-serif',
              color: "black",
            }}
          >
            1.4.
            <span style={{ font: '7pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <b>
            <span
              style={{
                fontSize: "12pt",
                lineHeight: "115%",
                fontFamily: '"Arial", sans-serif',
                color: "black",
              }}
            >
              Artist –
            </span>
          </b>
          <span
            style={{
              fontSize: "12pt",
              lineHeight: "115%",
              fontFamily: '"Arial", sans-serif',
              color: "black",
            }}
          >
            any Person that is an artist, designer or other maker of art
            registered or to be registered on the Website;
          </span>
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            marginTop: "0in",
            marginRight: "0in",
            marginBottom: "10pt",
            marginLeft: "42.55pt",
            textAlign: "justify",
            textIndent: "-42.55pt",
            lineHeight: "115%",
          }}
        >
          <span
            style={{
              fontSize: "12pt",
              lineHeight: "115%",
              fontFamily: '"Arial", sans-serif',
              color: "black",
            }}
          >
            1.5.
            <span style={{ font: '7pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <b>
            <span
              style={{
                fontSize: "12pt",
                lineHeight: "115%",
                fontFamily: '"Arial", sans-serif',
                color: "black",
              }}
            >
              Buyer
            </span>
          </b>
          <span
            style={{
              fontSize: "12pt",
              lineHeight: "115%",
              fontFamily: '"Arial", sans-serif',
              color: "black",
            }}
          >
            – any Person that accesses, visits and/or uses the Service in order
            to purchase the item listed on the Website;
          </span>
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            marginTop: "0in",
            marginRight: "0in",
            marginBottom: "10pt",
            marginLeft: "42.55pt",
            textAlign: "justify",
            textIndent: "-42.55pt",
            lineHeight: "115%",
          }}
        >
          <span
            style={{
              fontSize: "12pt",
              lineHeight: "115%",
              fontFamily: '"Arial", sans-serif',
              color: "black",
            }}
          >
            1.6.
            <span style={{ font: '7pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <b>
            <span
              style={{
                fontSize: "12pt",
                lineHeight: "115%",
                fontFamily: '"Arial", sans-serif',
                color: "black",
              }}
            >
              Seller
            </span>
          </b>
          <span
            style={{
              fontSize: "12pt",
              lineHeight: "115%",
              fontFamily: '"Arial", sans-serif',
              color: "black",
            }}
          >
            - any Person that accesses, visits and/or uses the Service in order
            to sell the item(s) on the Website;
          </span>
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            marginTop: "0in",
            marginRight: "0in",
            marginBottom: "10pt",
            marginLeft: "42.55pt",
            textAlign: "justify",
            textIndent: "-42.55pt",
            lineHeight: "115%",
          }}
        >
          <span
            style={{
              fontSize: "12pt",
              lineHeight: "115%",
              fontFamily: '"Arial", sans-serif',
              color: "black",
            }}
          >
            1.7.
            <span style={{ font: '7pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <b>
            <span
              style={{
                fontSize: "12pt",
                lineHeight: "115%",
                fontFamily: '"Arial", sans-serif',
                color: "black",
              }}
            >
              Gallery
            </span>
          </b>
          <span
            style={{
              fontSize: "12pt",
              lineHeight: "115%",
              fontFamily: '"Arial", sans-serif',
              color: "black",
            }}
          >
            – an legal entity that buys, sells, authenticates and displays works
            of art;
          </span>
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            marginTop: "0in",
            marginRight: "0in",
            marginBottom: "10pt",
            marginLeft: "42.55pt",
            textAlign: "justify",
            textIndent: "-42.55pt",
            lineHeight: "115%",
          }}
        >
          <span
            style={{
              fontSize: "12pt",
              lineHeight: "115%",
              fontFamily: '"Arial", sans-serif',
              color: "black",
            }}
          >
            1.8.
            <span style={{ font: '7pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <b>
            <span
              style={{
                fontSize: "12pt",
                lineHeight: "115%",
                fontFamily: '"Arial", sans-serif',
                color: "black",
              }}
            >
              Content
            </span>
          </b>
          <span
            style={{
              fontSize: "12pt",
              lineHeight: "115%",
              fontFamily: '"Arial", sans-serif',
              color: "black",
            }}
          >
            - any and all images, text, information, data, audio, video,
            graphics, computer code, software, and other material provided on or
            through the Services. Content includes both Foundation Content and
            User Content;
          </span>
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            marginTop: "0in",
            marginRight: "0in",
            marginBottom: "10pt",
            marginLeft: "42.55pt",
            textAlign: "justify",
            textIndent: "-42.55pt",
            lineHeight: "115%",
          }}
        >
          <span
            style={{
              fontSize: "12pt",
              lineHeight: "115%",
              fontFamily: '"Arial", sans-serif',
              color: "black",
            }}
          >
            1.9.
            <span style={{ font: '7pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <b>
            <span
              style={{
                fontSize: "12pt",
                lineHeight: "115%",
                fontFamily: '"Arial", sans-serif',
                color: "black",
              }}
            >
              Foundation Content
            </span>
          </b>
          <span
            style={{
              fontSize: "12pt",
              lineHeight: "115%",
              fontFamily: '"Arial", sans-serif',
              color: "black",
            }}
          >
            - any and all Content that the Foundation provides on or through the
            Services, including Content licensed from a third party, but
            excluding User Content;
          </span>
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            marginTop: "0in",
            marginRight: "0in",
            marginBottom: "10pt",
            marginLeft: "42.55pt",
            textAlign: "justify",
            textIndent: "-42.55pt",
            lineHeight: "115%",
          }}
        >
          <span
            style={{
              fontSize: "12pt",
              lineHeight: "115%",
              fontFamily: '"Arial", sans-serif',
              color: "black",
            }}
          >
            1.10.
            <span style={{ font: '7pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <b>
            <span
              style={{
                fontSize: "12pt",
                lineHeight: "115%",
                fontFamily: '"Arial", sans-serif',
                color: "black",
              }}
            >
              User Content
            </span>
          </b>
          <span
            style={{
              fontSize: "12pt",
              lineHeight: "115%",
              fontFamily: '"Arial", sans-serif',
              color: "black",
            }}
          >
            - any and all Content that the User submits, posts, publishes or
            otherwise provides on or through the Services;
          </span>
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            marginTop: "0in",
            marginRight: "0in",
            marginBottom: "10pt",
            marginLeft: "42.55pt",
            textAlign: "justify",
            textIndent: "-42.55pt",
            lineHeight: "115%",
          }}
        >
          <span
            style={{
              fontSize: "12pt",
              lineHeight: "115%",
              fontFamily: '"Arial", sans-serif',
              color: "black",
            }}
          >
            1.11.
            <span style={{ font: '7pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <b>
            <span
              style={{
                fontSize: "12pt",
                lineHeight: "115%",
                fontFamily: '"Arial", sans-serif',
                color: "black",
              }}
            >
              Foundation Parties
            </span>
          </b>
          <span
            style={{
              fontSize: "12pt",
              lineHeight: "115%",
              fontFamily: '"Arial", sans-serif',
              color: "black",
            }}
          >
            -
          </span>
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            marginTop: "0in",
            marginRight: "0in",
            marginBottom: "10pt",
            marginLeft: "42.55pt",
            textAlign: "justify",
            textIndent: "-42.55pt",
            lineHeight: "115%",
          }}
        >
          <span
            style={{
              fontSize: "12pt",
              lineHeight: "115%",
              fontFamily: '"Arial", sans-serif',
              color: "black",
            }}
          >
            1.12.
            <span style={{ font: '7pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <b>
            <span
              style={{
                fontSize: "12pt",
                lineHeight: "115%",
                fontFamily: '"Arial", sans-serif',
                color: "black",
              }}
            >
              Representations and warranties
            </span>
          </b>
          <span
            style={{
              fontSize: "12pt",
              lineHeight: "115%",
              fontFamily: '"Arial", sans-serif',
              color: "black",
            }}
          >
            - Representations and warranties of the parties referred to in
            Article - of the Agreement;
          </span>
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            marginTop: "0in",
            marginRight: "0in",
            marginBottom: "10pt",
            marginLeft: "42.55pt",
            textAlign: "justify",
            textIndent: "-42.55pt",
            lineHeight: "115%",
          }}
        >
          <span
            style={{
              fontSize: "12pt",
              lineHeight: "115%",
              fontFamily: '"Arial", sans-serif',
              color: "black",
            }}
          >
            1.13.
            <span style={{ font: '7pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <b>
            <span
              style={{
                fontSize: "12pt",
                lineHeight: "115%",
                fontFamily: '"Arial", sans-serif',
                color: "black",
              }}
            >
              Regulation
            </span>
          </b>
          <span
            style={{
              fontSize: "12pt",
              lineHeight: "115%",
              fontFamily: '"Arial", sans-serif',
              color: "black",
            }}
          >
            - Any regulation, rule, official directive, request or guideline
            (whether or not having the force of law) of any governmental,
            intergovernmental or supranational body, agency, department or
            regulatory, self-regulatory or other Authority or organization;
          </span>
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            marginTop: "0in",
            marginRight: "0in",
            marginBottom: "10pt",
            marginLeft: "42.55pt",
            textAlign: "justify",
            textIndent: "-42.55pt",
            lineHeight: "115%",
          }}
        >
          <span
            style={{
              fontSize: "12pt",
              lineHeight: "115%",
              fontFamily: '"Arial", sans-serif',
              color: "black",
            }}
          >
            1.14.
            <span style={{ font: '7pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <b>
            <span
              style={{
                fontSize: "12pt",
                lineHeight: "115%",
                fontFamily: '"Arial", sans-serif',
                color: "black",
              }}
            >
              Confidential information
            </span>
          </b>
          <span
            style={{
              fontSize: "12pt",
              lineHeight: "115%",
              fontFamily: '"Arial", sans-serif',
              color: "black",
            }}
          >
            – Any information provided by the Party in any form to the other
            party or/and any of its employees, on any basis and ground. Such
            information may be presented in various forms, in a material as well
            as in an electronic or oral form. Confidential information includes
            the fact of entering into this Agreement, its term, and the content;
          </span>
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            marginTop: "0in",
            marginRight: "0in",
            marginBottom: "10pt",
            marginLeft: "42.55pt",
            textAlign: "justify",
            textIndent: "-42.55pt",
            lineHeight: "115%",
          }}
        >
          <span
            style={{
              fontSize: "12pt",
              lineHeight: "115%",
              fontFamily: '"Arial", sans-serif',
              color: "black",
            }}
          >
            1.15.
            <span style={{ font: '7pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <b>
            <span
              style={{
                fontSize: "12pt",
                lineHeight: "115%",
                fontFamily: '"Arial", sans-serif',
                color: "black",
              }}
            >
              Third person
            </span>
          </b>
          <span
            style={{
              fontSize: "12pt",
              lineHeight: "115%",
              fontFamily: '"Arial", sans-serif',
              color: "black",
            }}
          >
            – Any person other than Foundation and User;
          </span>
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            marginTop: "0in",
            marginRight: "0in",
            marginBottom: "10pt",
            marginLeft: "42.55pt",
            textAlign: "justify",
            textIndent: "-42.55pt",
            lineHeight: "115%",
          }}
        >
          <span
            style={{
              fontSize: "12pt",
              lineHeight: "115%",
              fontFamily: '"Arial", sans-serif',
              color: "black",
            }}
          >
            1.16.
            <span style={{ font: '7pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <b>
            <span
              style={{
                fontSize: "12pt",
                lineHeight: "115%",
                fontFamily: '"Arial", sans-serif',
                color: "black",
              }}
            >
              Services
            </span>
          </b>
          <span
            style={{
              fontSize: "12pt",
              lineHeight: "115%",
              fontFamily: '"Arial", sans-serif',
              color: "black",
            }}
          >
            – any services and products provided by the Foundation through the
            Website, including but not limited to providing an online
            marketplace where Users may browse, buy, bid on, list, market, offer
            for sale, authenticate and sell work of art. The Services also
            include features that enable Users to contact and communicate with
            the Foundation or other Users about the property listed on the
            Services or otherwise;
          </span>
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            marginTop: "0in",
            marginRight: "0in",
            marginBottom: "10pt",
            marginLeft: "42.55pt",
            textAlign: "justify",
            textIndent: "-42.55pt",
            lineHeight: "115%",
          }}
        >
          <span
            style={{
              fontSize: "12pt",
              lineHeight: "115%",
              fontFamily: '"Arial", sans-serif',
              color: "black",
            }}
          >
            1.17.
            <span style={{ font: '7pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <b>
            <span
              style={{
                fontSize: "12pt",
                lineHeight: "115%",
                fontFamily: '"Arial", sans-serif',
                color: "black",
              }}
            >
              Person
            </span>
          </b>
          <span
            style={{
              fontSize: "12pt",
              lineHeight: "115%",
              fontFamily: '"Arial", sans-serif',
              color: "black",
            }}
          >
            - any natural person, legal entity, or/and other organizational unit
            provided for by the legislation of Georgia or other country, which
            is not a legal entity;
          </span>
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            marginTop: "0in",
            marginRight: "0in",
            marginBottom: "10pt",
            marginLeft: "42.55pt",
            textAlign: "justify",
            textIndent: "-42.55pt",
            lineHeight: "115%",
          }}
        >
          <span
            style={{
              fontSize: "12pt",
              lineHeight: "115%",
              fontFamily: '"Arial", sans-serif',
              color: "black",
            }}
          >
            1.18.
            <span style={{ font: '7pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <b>
            <span
              style={{
                fontSize: "12pt",
                lineHeight: "115%",
                fontFamily: '"Arial", sans-serif',
                color: "black",
              }}
            >
              Working day
            </span>
          </b>
          <span
            style={{
              fontSize: "12pt",
              lineHeight: "115%",
              fontFamily: '"Arial", sans-serif',
              color: "black",
            }}
          >
            - a day except Saturday, Sunday or any other day determined by the
            legislation;
          </span>
        </p>
        <p
          className="MsoListParagraphCxSpLast"
          style={{
            marginTop: "0in",
            marginRight: "0in",
            marginBottom: "10pt",
            marginLeft: "42.55pt",
            textAlign: "justify",
            textIndent: "-42.55pt",
            lineHeight: "115%",
          }}
        >
          <span
            style={{
              fontSize: "12pt",
              lineHeight: "115%",
              fontFamily: '"Arial", sans-serif',
              color: "black",
            }}
          >
            1.19.
            <span style={{ font: '7pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <b>
            <span
              style={{
                fontSize: "12pt",
                lineHeight: "115%",
                fontFamily: '"Arial", sans-serif',
                color: "black",
              }}
            >
              Agreement
            </span>
          </b>
          <span
            style={{
              fontSize: "12pt",
              lineHeight: "115%",
              fontFamily: '"Arial", sans-serif',
              color: "black",
            }}
          >
            – (a) this Agreement and (b) its Annexes (if any) that has been/will
            be concluded in the future between the parties to this Agreement,
            including periodical changes and additions to such documents.
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginLeft: "42.55pt",
            textAlign: "justify",
            textIndent: "-42.55pt",
          }}
        >
          <span
            style={{
              fontSize: "12pt",
              lineHeight: "107%",
              fontFamily: '"Arial", sans-serif',
              color: "black",
            }}
          >
            &nbsp;
          </span>
        </p>
        <p
          className="MsoListParagraphCxSpFirst"
          style={{
            marginLeft: "42.55pt",
            textAlign: "justify",
            textIndent: "-42.55pt",
          }}
        >
          <a name="_Toc73815234">
            <b>
              <span
                style={{
                  fontSize: "12pt",
                  lineHeight: "107%",
                  fontFamily: '"Arial", sans-serif',
                  color: "black",
                }}
              >
                2.
                <span style={{ font: '7pt "Times New Roman"' }}>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
              </span>
            </b>
            <b>
              <span
                style={{
                  fontSize: "12pt",
                  lineHeight: "107%",
                  fontFamily: '"Arial", sans-serif',
                  color: "black",
                }}
              >
                Using the Website
              </span>
            </b>
          </a>
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            marginLeft: "42.55pt",
            textAlign: "justify",
            textIndent: "-42.55pt",
          }}
        >
          <span
            style={{
              fontSize: "12pt",
              lineHeight: "107%",
              fontFamily: '"Arial", sans-serif',
              color: "black",
            }}
          >
            2.1.
            <span style={{ font: '7pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontSize: "12pt",
              lineHeight: "107%",
              fontFamily: '"Arial", sans-serif',
              color: "black",
            }}
          >
            By registering on the Website
          </span>
          <span
            lang="GEO/KAT"
            style={{
              fontSize: "12pt",
              lineHeight: "107%",
              fontFamily: '"Arial", sans-serif',
              color: "black",
            }}
          >
            ,
          </span>
          <span
            style={{
              fontSize: "12pt",
              lineHeight: "107%",
              fontFamily: '"Arial", sans-serif',
              color: "black",
            }}
          >
            purchasing an item listed on the Website
          </span>
          <span
            style={{
              fontSize: "12pt",
              lineHeight: "107%",
              fontFamily: '"Arial", sans-serif',
              color: "black",
            }}
          ></span>
          <span
            style={{
              fontSize: "12pt",
              lineHeight: "107%",
              fontFamily: '"Arial", sans-serif',
              color: "black",
            }}
          >
            or/and listing an item on the Website for a sale, the User engages
            in the Service and agrees to be bound by the Agreement. In
            particular: (a) the User, as a Seller, has the opportunity to
            dispose&nbsp;his/her&nbsp;proprietary items through the Website; (b)
            the User, as a Buyer, is given the opportunity to purchase items
            placed on the Website for sale by another user; (c) the User, as an
            Artist has the opportunity to register on the Website to participate
            in creation of a database of artists and display his/her works of
            art on the Website; (d) the User, as a Gallery has the opportunity
            to act as a Seller, thus, to have an opportunity to dispose
            proprietary items through the Website.
          </span>
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            marginLeft: "42.55pt",
            textAlign: "justify",
            textIndent: "-42.55pt",
          }}
        >
          <span
            style={{
              fontSize: "12pt",
              lineHeight: "107%",
              fontFamily: '"Arial", sans-serif',
              color: "black",
            }}
          >
            2.2.
            <span style={{ font: '7pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontSize: "12pt",
              lineHeight: "107%",
              fontFamily: '"Arial", sans-serif',
              color: "black",
            }}
          >
            The User is responsible for providing all equipment (such as
            computers or mobile devices), Internet and telecommunication service
            necessary to use the Services and/or to communicate with the
            Foundation, at his/her/its own cost. The User is responsible for all
            of the expenses in connection with this Agreement and use of the
            Services as a Seller, Buyer, Artist or Gallery.
          </span>
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            marginLeft: "42.55pt",
            textAlign: "justify",
            textIndent: "-42.55pt",
          }}
        >
          <span
            style={{
              fontSize: "12pt",
              lineHeight: "107%",
              fontFamily: '"Arial", sans-serif',
              color: "black",
            }}
          >
            2.3.
            <span style={{ font: '7pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontSize: "12pt",
              lineHeight: "107%",
              fontFamily: '"Arial", sans-serif',
              color: "black",
            }}
          >
            In connection with using or accessing the Services, the User agrees
            to comply with the Agreement and all applicable regulations. The
            User shall not:
          </span>
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            marginLeft: "42.55pt",
            textAlign: "justify",
            textIndent: "-42.55pt",
          }}
        >
          <span
            style={{
              fontSize: "12pt",
              lineHeight: "107%",
              fontFamily: '"Arial", sans-serif',
              color: "black",
            }}
          >
            2.3.1.
            <span style={{ font: '7pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontSize: "12pt",
              lineHeight: "107%",
              fontFamily: '"Arial", sans-serif',
              color: "black",
            }}
          >
            post, list or upload Content or items in inappropriate categories or
            areas on the Website;
          </span>
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            marginLeft: "42.55pt",
            textAlign: "justify",
            textIndent: "-42.55pt",
          }}
        >
          <span
            style={{
              fontSize: "12pt",
              lineHeight: "107%",
              fontFamily: '"Arial", sans-serif',
              color: "black",
            }}
          >
            2.3.2.
            <span style={{ font: '7pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontSize: "12pt",
              lineHeight: "107%",
              fontFamily: '"Arial", sans-serif',
              color: "black",
            }}
          >
            breach or circumvent any regulation, third-party rights;
          </span>
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            marginLeft: "42.55pt",
            textAlign: "justify",
            textIndent: "-42.55pt",
          }}
        >
          <span
            style={{
              fontSize: "12pt",
              lineHeight: "107%",
              fontFamily: '"Arial", sans-serif',
              color: "black",
            }}
          >
            2.3.3.
            <span style={{ font: '7pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontSize: "12pt",
              lineHeight: "107%",
              fontFamily: '"Arial", sans-serif',
              color: "black",
            }}
          >
            use the Services if he/she/it is not able to form legally binding
            contracts, or is a person with whom transactions are prohibited
            under economic or trade sanctions;
          </span>
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            marginLeft: "42.55pt",
            textAlign: "justify",
            textIndent: "-42.55pt",
          }}
        >
          <span
            style={{
              fontSize: "12pt",
              lineHeight: "107%",
              fontFamily: '"Arial", sans-serif',
              color: "black",
            }}
          >
            2.3.4.
            <span style={{ font: '7pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontSize: "12pt",
              lineHeight: "107%",
              fontFamily: '"Arial", sans-serif',
              color: "black",
            }}
          >
            fail to pay for items purchased by the Buyer, unless there is a
            valid reason;
          </span>
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            marginLeft: "42.55pt",
            textAlign: "justify",
            textIndent: "-42.55pt",
          }}
        >
          <span
            style={{
              fontSize: "12pt",
              lineHeight: "107%",
              fontFamily: '"Arial", sans-serif',
              color: "black",
            }}
          >
            2.3.5.
            <span style={{ font: '7pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontSize: "12pt",
              lineHeight: "107%",
              fontFamily: '"Arial", sans-serif',
              color: "black",
            }}
          >
            fail to deliver items sold by the Seller, unless there is a valid
            reason;
          </span>
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            marginLeft: "42.55pt",
            textAlign: "justify",
            textIndent: "-42.55pt",
          }}
        >
          <span
            style={{
              fontSize: "12pt",
              lineHeight: "107%",
              fontFamily: '"Arial", sans-serif',
              color: "black",
            }}
          >
            2.3.6.
            <span style={{ font: '7pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontSize: "12pt",
              lineHeight: "107%",
              fontFamily: '"Arial", sans-serif',
              color: "black",
            }}
          >
            post false, inaccurate, misleading, deceptive, defamatory, or
            libelous User Content;
          </span>
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            marginLeft: "42.55pt",
            textAlign: "justify",
            textIndent: "-42.55pt",
          }}
        >
          <span
            style={{
              fontSize: "12pt",
              lineHeight: "107%",
              fontFamily: '"Arial", sans-serif',
              color: "black",
            }}
          >
            2.3.7.
            <span style={{ font: '7pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontSize: "12pt",
              lineHeight: "107%",
              fontFamily: '"Arial", sans-serif',
              color: "black",
            }}
          >
            transfer the account and user ID to another party without prior
            consent of the Foundation;
          </span>
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            marginLeft: "42.55pt",
            textAlign: "justify",
            textIndent: "-42.55pt",
          }}
        >
          <span
            style={{
              fontSize: "12pt",
              lineHeight: "107%",
              fontFamily: '"Arial", sans-serif',
              color: "black",
            }}
          >
            2.3.8.
            <span style={{ font: '7pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontSize: "12pt",
              lineHeight: "107%",
              fontFamily: '"Arial", sans-serif',
              color: "black",
            }}
          >
            infringe the copyright, trademark, patent, publicity, moral,
            database, and/or other intellectual property rights (collectively,
            "Intellectual Property Rights") that belong to or are licensed to
            the Foundation. Some, but not all, actions that may constitute
            infringement are reproducing, performing, displaying, distributing,
            copying, reverse engineering, decompiling, disassembling, or
            preparing derivative works from Foundation Content or the content
            that belongs to someone else;
          </span>
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            marginLeft: "42.55pt",
            textAlign: "justify",
            textIndent: "-42.55pt",
          }}
        >
          <span
            style={{
              fontSize: "12pt",
              lineHeight: "107%",
              fontFamily: '"Arial", sans-serif',
              color: "black",
            }}
          >
            2.3.9.
            <span style={{ font: '7pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontSize: "12pt",
              lineHeight: "107%",
              fontFamily: '"Arial", sans-serif',
              color: "black",
            }}
          >
            infringe any Intellectual Property Rights that belong to third
            parties affected by his/her/its use of the Service or post Content
            that does not belong to him/her/it;
          </span>
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            marginLeft: "42.55pt",
            textAlign: "justify",
            textIndent: "-42.55pt",
          }}
        >
          <span
            style={{
              fontSize: "12pt",
              lineHeight: "107%",
              fontFamily: '"Arial", sans-serif',
              color: "black",
            }}
          >
            2.3.10.
            <span style={{ font: '7pt "Times New Roman"' }}>&nbsp;&nbsp;</span>
          </span>
          <span
            style={{
              fontSize: "12pt",
              lineHeight: "107%",
              fontFamily: '"Arial", sans-serif',
              color: "black",
            }}
          >
            harvest or otherwise collect information about users without their
            consent.
          </span>
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            marginLeft: "42.55pt",
            textAlign: "justify",
            textIndent: "-42.55pt",
          }}
        >
          <span
            style={{
              fontSize: "12pt",
              lineHeight: "107%",
              fontFamily: '"Arial", sans-serif',
              color: "black",
            }}
          >
            2.4.
            <span style={{ font: '7pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontSize: "12pt",
              lineHeight: "107%",
              fontFamily: '"Arial", sans-serif',
              color: "black",
            }}
          >
            If the Foundation considers that the User is abusing the Website
            and/or the Services in any way, the Foundation is entitled to, in
            its sole discretion and without limiting other remedies, limit,
            suspend, or terminate the User's account(s) and access to the
            Services, delay or remove hosted User Content, remove any special
            status associated with the User's account(s), remove, not display,
            and/or demote listings, reduce or eliminate any discounts, and take
            technical and/or legal steps to prevent the User from using the
            Services.
          </span>
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            marginLeft: "42.55pt",
            textAlign: "justify",
            textIndent: "-42.55pt",
          }}
        >
          <span
            style={{
              fontSize: "12pt",
              lineHeight: "107%",
              fontFamily: '"Arial", sans-serif',
              color: "black",
            }}
          >
            2.5.
            <span style={{ font: '7pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontSize: "12pt",
              lineHeight: "107%",
              fontFamily: '"Arial", sans-serif',
              color: "black",
            }}
          >
            The Foundation is entitled to cancel unconfirmed accounts or
            accounts that have been inactive for a substantial period of time.
          </span>
        </p>
        <p
          className="MsoListParagraphCxSpLast"
          style={{
            marginLeft: "42.55pt",
            textAlign: "justify",
            textIndent: "-42.55pt",
          }}
        >
          <span
            style={{
              fontSize: "12pt",
              lineHeight: "107%",
              fontFamily: '"Arial", sans-serif',
              color: "black",
            }}
          >
            2.6.
            <span style={{ font: '7pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontSize: "12pt",
              lineHeight: "107%",
              fontFamily: '"Arial", sans-serif',
              color: "black",
            }}
          >
            The Foundation reserves the right to refuse, modify, or terminate
            all or part of the Services to anyone for any reason at its
            discretion.
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginLeft: "42.55pt",
            textAlign: "justify",
            textIndent: "-42.55pt",
          }}
        >
          <span
            style={{
              fontSize: "12pt",
              lineHeight: "107%",
              fontFamily: '"Arial", sans-serif',
              color: "black",
            }}
          >
            &nbsp;
          </span>
        </p>
        <p
          className="MsoListParagraphCxSpFirst"
          style={{
            marginLeft: "42.55pt",
            textAlign: "justify",
            textIndent: "-42.55pt",
          }}
        >
          <a name="_Toc73815235">
            <b>
              <span
                style={{
                  fontSize: "12pt",
                  lineHeight: "107%",
                  fontFamily: '"Arial", sans-serif',
                  color: "black",
                }}
              >
                3.
                <span style={{ font: '7pt "Times New Roman"' }}>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
              </span>
            </b>
            <b>
              <span
                style={{
                  fontSize: "12pt",
                  lineHeight: "107%",
                  fontFamily: '"Arial", sans-serif',
                  color: "black",
                }}
              >
                Listing Conditions
              </span>
            </b>
          </a>
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            marginLeft: "42.55pt",
            textAlign: "justify",
            textIndent: "-42.55pt",
          }}
        >
          <span
            style={{
              fontSize: "12pt",
              lineHeight: "107%",
              fontFamily: '"Arial", sans-serif',
              color: "black",
            }}
          >
            3.1.
            <span style={{ font: '7pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontSize: "12pt",
              lineHeight: "107%",
              fontFamily: '"Arial", sans-serif',
              color: "black",
            }}
          >
            Unless agreed otherwise in writing, all items will be listed,
            marketed, offered, and sold under the Seller’s name. Seller agrees
            that the Foundation may use Seller’s name in connection with the
            listing, marketing, offer, and sale of any and all items by the
            Seller on and in connection with the Services, and to inform others
            of the sellers that use the Services.
          </span>
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            marginLeft: "42.55pt",
            textAlign: "justify",
            textIndent: "-42.55pt",
          }}
        >
          <span
            style={{
              fontSize: "12pt",
              lineHeight: "107%",
              fontFamily: '"Arial", sans-serif',
              color: "black",
            }}
          >
            3.2.
            <span style={{ font: '7pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontSize: "12pt",
              lineHeight: "107%",
              fontFamily: '"Arial", sans-serif',
              color: "black",
            }}
          >
            When listing an item for sale on the Website, the User agrees that:
          </span>
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            marginLeft: "63.8pt",
            textAlign: "justify",
            textIndent: "-14.2pt",
          }}
        >
          <span
            style={{
              fontSize: "12pt",
              lineHeight: "107%",
              fontFamily: '"Arial", sans-serif',
              color: "black",
            }}
          >
            a.<span style={{ font: '7pt "Times New Roman"' }}>&nbsp; </span>
          </span>
          <span
            style={{
              fontSize: "12pt",
              lineHeight: "107%",
              fontFamily: '"Arial", sans-serif',
              color: "black",
            }}
          >
            the User assumes full responsibility for the item offered and the
            accuracy and content of the listing;
          </span>
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            marginLeft: "63.8pt",
            textAlign: "justify",
            textIndent: "-14.2pt",
          }}
        >
          <span
            style={{
              fontSize: "12pt",
              lineHeight: "107%",
              fontFamily: '"Arial", sans-serif',
              color: "black",
            }}
          >
            b.<span style={{ font: '7pt "Times New Roman"' }}>&nbsp; </span>
          </span>
          <span
            style={{
              fontSize: "12pt",
              lineHeight: "107%",
              fontFamily: '"Arial", sans-serif',
              color: "black",
            }}
          >
            the listing may not be immediately searchable by keyword or category
            for several hours. The Foundation can't guarantee exact listing
            duration;
          </span>
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            marginLeft: "63.8pt",
            textAlign: "justify",
            textIndent: "-14.2pt",
          }}
        >
          <span
            style={{
              fontSize: "12pt",
              lineHeight: "107%",
              fontFamily: '"Arial", sans-serif',
              color: "black",
            }}
          >
            c.
            <span style={{ font: '7pt "Times New Roman"' }}>&nbsp;&nbsp;</span>
          </span>
          <span
            style={{
              fontSize: "12pt",
              lineHeight: "107%",
              fontFamily: '"Arial", sans-serif',
              color: "black",
            }}
          >
            The User content complies with this Agreement and Regulation;
          </span>
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            marginLeft: "63.8pt",
            textAlign: "justify",
            textIndent: "-14.2pt",
          }}
        >
          <span
            style={{
              fontSize: "12pt",
              lineHeight: "107%",
              fontFamily: '"Arial", sans-serif',
              color: "black",
            }}
          >
            d.<span style={{ font: '7pt "Times New Roman"' }}>&nbsp; </span>
          </span>
          <span
            style={{
              fontSize: "12pt",
              lineHeight: "107%",
              fontFamily: '"Arial", sans-serif',
              color: "black",
            }}
          >
            User Content that violates any of the provisions of this Agreement
            or/and Regulation may be modified, obfuscated or deleted at the
            Foundations sole discretion;
          </span>
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            marginLeft: "63.8pt",
            textAlign: "justify",
            textIndent: "-14.2pt",
          }}
        >
          <span
            style={{
              fontSize: "12pt",
              lineHeight: "107%",
              fontFamily: '"Arial", sans-serif',
              color: "black",
            }}
          >
            e.<span style={{ font: '7pt "Times New Roman"' }}>&nbsp; </span>
          </span>
          <span
            style={{
              fontSize: "12pt",
              lineHeight: "107%",
              fontFamily: '"Arial", sans-serif',
              color: "black",
            }}
          >
            The Foundation may revise item data associated with listings to
            supplement, remove, or correct information.
          </span>
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            marginLeft: "42.55pt",
            textAlign: "justify",
            textIndent: "-42.55pt",
          }}
        >
          <span
            style={{
              fontSize: "12pt",
              lineHeight: "107%",
              fontFamily: '"Arial", sans-serif',
              color: "black",
            }}
          >
            3.3.
            <span style={{ font: '7pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontSize: "12pt",
              lineHeight: "107%",
              fontFamily: '"Arial", sans-serif',
              color: "black",
            }}
          >
            Information in any listing, condition, or other reports, commentary
            or valuation, is not a representation of fact but rather a statement
            of belief held by the Foundation based solely upon information
            provided thereto by the User.
          </span>
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            marginLeft: "42.55pt",
            textAlign: "justify",
            textIndent: "-42.55pt",
          }}
        >
          <span
            style={{
              fontSize: "12pt",
              lineHeight: "107%",
              fontFamily: '"Arial", sans-serif',
              color: "black",
            }}
          >
            3.4.
            <span style={{ font: '7pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontSize: "12pt",
              lineHeight: "107%",
              fontFamily: '"Arial", sans-serif',
              color: "black",
            }}
          >
            The Foundation disclaims that Illustrations of the listed item are
            for identification purposes only and cannot be used as precise
            indications of size or to convey full information as to the actual
            condition of the item. Upon request, additional condition reports
            may be furnished by Seller to Buyer at his/her/its own cost and
            expense.
          </span>
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            marginLeft: "42.55pt",
            textAlign: "justify",
            textIndent: "-42.55pt",
          }}
        >
          <span
            style={{
              fontSize: "12pt",
              lineHeight: "107%",
              fontFamily: '"Arial", sans-serif',
              color: "black",
            }}
          >
            3.5.
            <span style={{ font: '7pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontSize: "12pt",
              lineHeight: "107%",
              fontFamily: '"Arial", sans-serif',
              color: "black",
            }}
          >
            The User warrants and guarantees that he/she/it will not sell and
            will promptly remove all listings for any item recalled by a
            manufacturer or governmental agency if the sale of the product is
            prohibited by law or regulation or the product poses a health or
            safety hazard as specified by any governmental agency. The
            Foundation has no responsibility or liability for the safety or
            performance of any product that the User lists or sells through the
            Services, including any product that is subject to a recall. The
            User is solely responsible for any non-conformity or defect in, or
            compliance with any public or private recall of any product the User
            lists or sells through the Services.
          </span>
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            marginLeft: "42.55pt",
            textAlign: "justify",
            textIndent: "-42.55pt",
          }}
        >
          <span
            style={{
              fontSize: "12pt",
              lineHeight: "107%",
              fontFamily: '"Arial", sans-serif',
              color: "black",
            }}
          >
            &nbsp;
          </span>
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            marginLeft: "42.55pt",
            textAlign: "justify",
            textIndent: "-42.55pt",
          }}
        >
          <a name="_Toc73815236">
            <b>
              <span
                style={{
                  fontSize: "12pt",
                  lineHeight: "107%",
                  fontFamily: '"Arial", sans-serif',
                  color: "black",
                }}
              >
                4.
                <span style={{ font: '7pt "Times New Roman"' }}>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
              </span>
            </b>
            <b>
              <span
                style={{
                  fontSize: "12pt",
                  lineHeight: "107%",
                  fontFamily: '"Arial", sans-serif',
                  color: "black",
                }}
              >
                Art Sale Policy
              </span>
            </b>
          </a>
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            marginLeft: "42.55pt",
            textAlign: "justify",
            textIndent: "-42.55pt",
          }}
        >
          <span
            style={{
              fontSize: "12pt",
              lineHeight: "107%",
              fontFamily: '"Arial", sans-serif',
              color: "black",
            }}
          >
            4.1.
            <span style={{ font: '7pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontSize: "12pt",
              lineHeight: "107%",
              fontFamily: '"Arial", sans-serif',
              color: "black",
            }}
          >
            The Foundation encourages the lawful sale of art on its Website.
          </span>
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            marginLeft: "42.55pt",
            textAlign: "justify",
            textIndent: "-42.55pt",
          }}
        >
          <span
            style={{
              fontSize: "12pt",
              lineHeight: "107%",
              fontFamily: '"Arial", sans-serif',
              color: "black",
            }}
          >
            4.2.
            <span style={{ font: '7pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontSize: "12pt",
              lineHeight: "107%",
              fontFamily: '"Arial", sans-serif',
              color: "black",
            }}
          >
            By registering on the Website, the User undertakes the obligation to
            abide by the guidelines set forth in this Article of the Agreement.
            If the User fails to do so, the item may be removed, and the User
            may be subject to restrictions of buying and selling privileges,
            also suspension of his/her/its account.
          </span>
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            marginLeft: "42.55pt",
            textAlign: "justify",
            textIndent: "-42.55pt",
          }}
        >
          <span
            style={{
              fontSize: "12pt",
              lineHeight: "107%",
              fontFamily: '"Arial", sans-serif',
              color: "black",
            }}
          >
            4.3.
            <span style={{ font: '7pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontSize: "12pt",
              lineHeight: "107%",
              fontFamily: '"Arial", sans-serif',
              color: "black",
            }}
          >
            The following guidelines are considered "general trade practice"
            within the art community, therefore, the Seller is encouraged to
            abide by these guidelines when selling works of art on the website:
          </span>
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            marginLeft: "42.55pt",
            textAlign: "justify",
            textIndent: "-42.55pt",
          }}
        >
          <span
            style={{
              fontSize: "12pt",
              lineHeight: "107%",
              fontFamily: '"Arial", sans-serif',
              color: "black",
            }}
          >
            4.4.
            <span style={{ font: '7pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontSize: "12pt",
              lineHeight: "107%",
              fontFamily: '"Arial", sans-serif',
              color: "black",
            }}
          >
            The Seller is allowed to:
          </span>
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            marginLeft: "42.55pt",
            textAlign: "justify",
            textIndent: "-42.55pt",
          }}
        >
          <span
            style={{
              fontSize: "12pt",
              lineHeight: "107%",
              fontFamily: '"Arial", sans-serif',
              color: "black",
            }}
          >
            4.4.1.
            <span style={{ font: '7pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontSize: "12pt",
              lineHeight: "107%",
              fontFamily: '"Arial", sans-serif',
              color: "black",
            }}
          >
            Sell his/her own original work of art;
          </span>
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            marginLeft: "42.55pt",
            textAlign: "justify",
            textIndent: "-42.55pt",
          }}
        >
          <span
            style={{
              fontSize: "12pt",
              lineHeight: "107%",
              fontFamily: '"Arial", sans-serif',
              color: "black",
            }}
          >
            4.4.2.
            <span style={{ font: '7pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontSize: "12pt",
              lineHeight: "107%",
              fontFamily: '"Arial", sans-serif',
              color: "black",
            }}
          >
            Sell the original work of art that he/she is authorized to resell.
          </span>
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            marginLeft: "42.55pt",
            textAlign: "justify",
            textIndent: "-42.55pt",
          }}
        >
          <span
            style={{
              fontSize: "12pt",
              lineHeight: "107%",
              fontFamily: '"Arial", sans-serif',
              color: "black",
            }}
          >
            4.5.
            <span style={{ font: '7pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontSize: "12pt",
              lineHeight: "107%",
              fontFamily: '"Arial", sans-serif',
              color: "black",
            }}
          >
            The Seller is not allowed to:
          </span>
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            marginLeft: "42.55pt",
            textAlign: "justify",
            textIndent: "-42.55pt",
          }}
        >
          <span
            style={{
              fontSize: "12pt",
              lineHeight: "107%",
              fontFamily: '"Arial", sans-serif',
              color: "black",
            }}
          >
            4.5.1.
            <span style={{ font: '7pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontSize: "12pt",
              lineHeight: "107%",
              fontFamily: '"Arial", sans-serif',
              color: "black",
            }}
          >
            Knowingly sell, exhibit, trade, produce, or advertise any
            unauthorized copy or reproduction of any work of art;
          </span>
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            marginLeft: "42.55pt",
            textAlign: "justify",
            textIndent: "-42.55pt",
          }}
        >
          <span
            style={{
              fontSize: "12pt",
              lineHeight: "107%",
              fontFamily: '"Arial", sans-serif',
              color: "black",
            }}
          >
            4.5.2.
            <span style={{ font: '7pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontSize: "12pt",
              lineHeight: "107%",
              fontFamily: '"Arial", sans-serif',
              color: "black",
            }}
          >
            Disclaim the knowledge of, or responsibility for, the authenticity
            or legality of an item that the Seller describes as genuine;
          </span>
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            marginLeft: "42.55pt",
            textAlign: "justify",
            textIndent: "-42.55pt",
          }}
        >
          <span
            style={{
              fontSize: "12pt",
              lineHeight: "107%",
              fontFamily: '"Arial", sans-serif',
              color: "black",
            }}
          >
            4.5.3.
            <span style={{ font: '7pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontSize: "12pt",
              lineHeight: "107%",
              fontFamily: '"Arial", sans-serif',
              color: "black",
            }}
          >
            Describe or market any item as genuine if the Seller cannot provide
            reasonable evidence to prove its authenticity;
          </span>
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            marginLeft: "42.55pt",
            textAlign: "justify",
            textIndent: "-42.55pt",
          }}
        >
          <span
            style={{
              fontSize: "12pt",
              lineHeight: "107%",
              fontFamily: '"Arial", sans-serif',
              color: "black",
            }}
          >
            4.5.4.
            <span style={{ font: '7pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontSize: "12pt",
              lineHeight: "107%",
              fontFamily: '"Arial", sans-serif',
              color: "black",
            }}
          >
            Knowingly participate in any way in the advertisement or sale of any
            work of art using any deceptive practices, including, but not
            limited to, false or misleading claims of the item's scarcity,
            value, provenance, condition or investment potential;
          </span>
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            marginLeft: "42.55pt",
            textAlign: "justify",
            textIndent: "-42.55pt",
          }}
        >
          <span
            style={{
              fontSize: "12pt",
              lineHeight: "107%",
              fontFamily: '"Arial", sans-serif',
              color: "black",
            }}
          >
            4.6.
            <span style={{ font: '7pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontSize: "12pt",
              lineHeight: "107%",
              fontFamily: '"Arial", sans-serif',
              color: "black",
            }}
          >
            The Seller may include the artist's name in the title of the listing
            only when listing a work by the artist him/herself. It is prohibited
            to include the artist's name when listing a work done "in the style
            of" an artist.
          </span>
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            marginLeft: "42.55pt",
            textAlign: "justify",
            textIndent: "-42.55pt",
          }}
        >
          <span
            style={{
              fontSize: "12pt",
              lineHeight: "107%",
              fontFamily: '"Arial", sans-serif',
              color: "black",
            }}
          >
            4.7.
            <span style={{ font: '7pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontSize: "12pt",
              lineHeight: "107%",
              fontFamily: '"Arial", sans-serif',
              color: "black",
            }}
          >
            If any alterations, conservation, or repairs have been performed on
            the work of art, the Seller is obligated to disclose this
            information in a clear and prominent manner within the item
            description.
          </span>
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            marginLeft: "42.55pt",
            textAlign: "justify",
            textIndent: "-42.55pt",
          }}
        >
          <span
            style={{
              fontSize: "12pt",
              lineHeight: "107%",
              fontFamily: '"Arial", sans-serif',
              color: "black",
            }}
          >
            4.8.
            <span style={{ font: '7pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontSize: "12pt",
              lineHeight: "107%",
              fontFamily: '"Arial", sans-serif',
              color: "black",
            }}
          >
            When selling art on the Website, the Seller shall abide by all laws
            relating to the sales of works of art and assist in the prosecution
            of violators of the law in this respect.
          </span>
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            marginLeft: "42.55pt",
            textAlign: "justify",
            textIndent: "-42.55pt",
          }}
        >
          <span
            style={{
              fontSize: "12pt",
              lineHeight: "107%",
              fontFamily: '"Arial", sans-serif',
              color: "black",
            }}
          >
            4.9.
            <span style={{ font: '7pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontSize: "12pt",
              lineHeight: "107%",
              fontFamily: '"Arial", sans-serif',
              color: "black",
            }}
          >
            The Website may include features and settings that enable Sellers to
            provide information with respect to artists, designers or other
            makers that created item listed or offered for sale by the Seller.
            The Seller agrees that all provided information with respect to each
            artists, designers or other makers will be true and accurate, that
            will not misrepresent his/her/its affiliation or relationship on or
            in connection with the Services, and that all such information and
            settings on the Services will update as necessary to keep them
            accurate and current. In addition to any information, the Seller may
            provide about artists, designers or other makers, the Foundation
            reserves the right to publish the information that are created and
            edited by the Foundation at its sole discretion.
          </span>
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            marginLeft: "42.55pt",
            textAlign: "justify",
            textIndent: "-42.55pt",
          }}
        >
          <span
            style={{
              fontSize: "12pt",
              lineHeight: "107%",
              fontFamily: '"Arial", sans-serif',
              color: "black",
            }}
          >
            4.10.
            <span style={{ font: '7pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontSize: "12pt",
              lineHeight: "107%",
              fontFamily: '"Arial", sans-serif',
              color: "black",
            }}
          >
            Prior to posting the listing of the Seller on the Website, the
            Seller may be requested to ship the work of art to the Foundation
            for authentication purposes.
          </span>
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            marginLeft: "42.55pt",
            textAlign: "justify",
            textIndent: "-42.55pt",
          }}
        >
          <span
            style={{
              fontSize: "12pt",
              lineHeight: "107%",
              fontFamily: '"Arial", sans-serif',
              color: "black",
            }}
          >
            4.11.
            <span style={{ font: '7pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontSize: "12pt",
              lineHeight: "107%",
              fontFamily: '"Arial", sans-serif',
              color: "black",
            }}
          >
            The Seller acknowledges and agrees that The Foundation's
            authentication process is in-house and independent. Works of art
            listed by the Galleries on the Website are not involved in the
            authentication process of the Foundation. None of the Artists,
            artworks of which is sold by the Seller (other then himself/herself)
            assumes any responsibility for any items purchased from or through
            the Website. Unless stated otherwise, the works of art sold on the
            Website are not partnered or affiliated with the Foundation in any
            manner.
          </span>
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            marginLeft: "42.55pt",
            textAlign: "justify",
            textIndent: "-42.55pt",
          }}
        >
          <span
            style={{
              fontSize: "12pt",
              lineHeight: "107%",
              fontFamily: '"Arial", sans-serif',
              color: "black",
            }}
          >
            4.12.
            <span style={{ font: '7pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontSize: "12pt",
              lineHeight: "107%",
              fontFamily: '"Arial", sans-serif',
              color: "black",
            }}
          >
            The knowledge of the Foundation in relation to each work of art is
            partially dependent on information provided to us by the
            authenticator, and the Foundation is not able to and does not carry
            out exhaustive due diligence on each work of art. The Foundation
            shall exercise reasonable care when making express statements in
            descriptions or condition reports "as is" and in light of (i) the
            information provided to us by the authenticator, (ii) scholarship
            and technical knowledge and (iii) the generally accepted opinions of
            relevant experts, in each case at the time any such express
            statement is made.
          </span>
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            marginLeft: "42.55pt",
            textAlign: "justify",
            textIndent: "-42.55pt",
          }}
        >
          <span
            style={{
              fontSize: "12pt",
              lineHeight: "107%",
              fontFamily: '"Arial", sans-serif',
              color: "black",
            }}
          >
            4.13.
            <span style={{ font: '7pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontSize: "12pt",
              lineHeight: "107%",
              fontFamily: '"Arial", sans-serif',
              color: "black",
            }}
          >
            All representations or statements made by the Foundation as to the
            authorship, origin, date, age, size, medium, attribution,
            genuineness, provenance, condition or estimated selling price of any
            work of art, is a statement of opinion only. All photographic
            representations and other illustrations presented online or
            elsewhere are solely for guidance and are not to be relied upon in
            terms of tone or color or necessarily to reveal any imperfections in
            the work of art. Many works are of an age or nature that precludes
            their being in pristine condition and some make reference to damage
            and/or restoration. Such information is given for guidance only and
            the absence of such a reference does not imply that a work is free
            from defects nor does any reference to particular defects imply the
            absence of others.
          </span>
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            marginLeft: "42.55pt",
            textAlign: "justify",
            textIndent: "-42.55pt",
          }}
        >
          <span
            style={{
              fontSize: "12pt",
              lineHeight: "107%",
              fontFamily: '"Arial", sans-serif',
              color: "black",
            }}
          >
            &nbsp;
          </span>
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            marginLeft: "42.55pt",
            textAlign: "justify",
            textIndent: "-42.55pt",
          }}
        >
          <a name="_Toc73815237">
            <b>
              <span
                style={{
                  fontSize: "12pt",
                  lineHeight: "107%",
                  fontFamily: '"Arial", sans-serif',
                  color: "black",
                }}
              >
                5.
                <span style={{ font: '7pt "Times New Roman"' }}>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
              </span>
            </b>
            <b>
              <span
                style={{
                  fontSize: "12pt",
                  lineHeight: "107%",
                  fontFamily: '"Arial", sans-serif',
                  color: "black",
                }}
              >
                Foundation Sale
              </span>
            </b>
          </a>
        </p>
        <p
          className="MsoListParagraphCxSpLast"
          style={{
            marginLeft: "42.55pt",
            textAlign: "justify",
            textIndent: "-42.55pt",
          }}
        >
          <span
            style={{
              fontSize: "12pt",
              lineHeight: "107%",
              fontFamily: '"Arial", sans-serif',
              color: "black",
            }}
          >
            5.1.
            <span style={{ font: '7pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontSize: "12pt",
              lineHeight: "107%",
              fontFamily: '"Arial", sans-serif',
              color: "black",
            }}
          >
            On occasion, the Foundation itself may sell items through the
            Website. If the Foundation are the seller of an item, the Foundation
            will expressly identify the Foundation as the seller on the Website
            and when performing a transaction with the User, and with respect to
            that item, the Foundation will be considered the seller under this
            Agreement. In all other cases, whether an auction, online purchase
            or otherwise, the Foundation is not the Seller and will have none of
            the Seller’s obligations or liability, whether under the Agreement
            or otherwise.
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginLeft: "42.55pt",
            textAlign: "justify",
            textIndent: "-42.55pt",
          }}
        >
          <span
            style={{
              fontSize: "12pt",
              lineHeight: "107%",
              fontFamily: '"Arial", sans-serif',
              color: "black",
            }}
          >
            &nbsp;
          </span>
        </p>
        <p
          className="MsoListParagraphCxSpFirst"
          style={{
            marginLeft: "42.55pt",
            textAlign: "justify",
            textIndent: "-42.55pt",
          }}
        >
          <a name="_Toc73815238">
            <b>
              <span
                style={{
                  fontSize: "12pt",
                  lineHeight: "107%",
                  fontFamily: '"Arial", sans-serif',
                  color: "black",
                }}
              >
                6.
                <span style={{ font: '7pt "Times New Roman"' }}>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
              </span>
            </b>
            <b>
              <span
                style={{
                  fontSize: "12pt",
                  lineHeight: "107%",
                  fontFamily: '"Arial", sans-serif',
                  color: "black",
                }}
              >
                Purchase Conditions
              </span>
            </b>
          </a>
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            marginLeft: "42.55pt",
            textAlign: "justify",
            textIndent: "-42.55pt",
          }}
        >
          <span
            style={{
              fontSize: "12pt",
              lineHeight: "107%",
              fontFamily: '"Arial", sans-serif',
              color: "black",
            }}
          >
            6.1.
            <span style={{ font: '7pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontSize: "12pt",
              lineHeight: "107%",
              fontFamily: '"Arial", sans-serif',
              color: "black",
            }}
          >
            When buying an item on the Website, the Buyer agrees that:
          </span>
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            marginLeft: "63.8pt",
            textAlign: "justify",
            textIndent: "-14.2pt",
          }}
        >
          <span
            style={{
              fontSize: "12pt",
              lineHeight: "107%",
              fontFamily: '"Arial", sans-serif',
              color: "black",
            }}
          >
            a.<span style={{ font: '7pt "Times New Roman"' }}>&nbsp; </span>
          </span>
          <span
            style={{
              fontSize: "12pt",
              lineHeight: "107%",
              fontFamily: '"Arial", sans-serif',
              color: "black",
            }}
          >
            the Buyer is responsible for reading the full item listing before
            committing to buy;
          </span>
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            marginLeft: "63.8pt",
            textAlign: "justify",
            textIndent: "-14.2pt",
          }}
        >
          <span
            style={{
              fontSize: "12pt",
              lineHeight: "107%",
              fontFamily: '"Arial", sans-serif',
              color: "black",
            }}
          >
            b.<span style={{ font: '7pt "Times New Roman"' }}>&nbsp; </span>
          </span>
          <span
            style={{
              fontSize: "12pt",
              lineHeight: "107%",
              fontFamily: '"Arial", sans-serif',
              color: "black",
            }}
          >
            the Buyer enters into a legally binding contract to purchase an item
            when the Buyer commits to buy an item, the Buyer offers for an item
            is accepted
          </span>
          <span
            lang="GEO/KAT"
            style={{
              fontSize: "12pt",
              lineHeight: "107%",
              fontFamily: '"Arial", sans-serif',
              color: "black",
            }}
          >
            ;
          </span>
        </p>
        <p
          className="MsoListParagraphCxSpLast"
          style={{
            marginLeft: "63.8pt",
            textAlign: "justify",
            textIndent: "-14.2pt",
          }}
        >
          <span
            style={{
              fontSize: "12pt",
              lineHeight: "107%",
              fontFamily: '"Arial", sans-serif',
              color: "black",
            }}
          >
            c.
            <span style={{ font: '7pt "Times New Roman"' }}>&nbsp;&nbsp;</span>
          </span>
          <span
            style={{
              fontSize: "12pt",
              lineHeight: "107%",
              fontFamily: '"Arial", sans-serif',
              color: "black",
            }}
          >
            the Foundation is not responsible for transferring the legal
            ownership of items from the Seller to Buyer.
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginLeft: "42.55pt",
            textAlign: "justify",
            textIndent: "-42.55pt",
          }}
        >
          <span
            style={{
              fontSize: "12pt",
              lineHeight: "107%",
              fontFamily: '"Arial", sans-serif',
              color: "black",
            }}
          >
            &nbsp;
          </span>
        </p>
        <p
          className="MsoListParagraphCxSpFirst"
          style={{
            marginLeft: "42.55pt",
            textAlign: "justify",
            textIndent: "-42.55pt",
          }}
        >
          <a name="_Toc73815239">
            <b>
              <span
                style={{
                  fontSize: "12pt",
                  lineHeight: "107%",
                  fontFamily: '"Arial", sans-serif',
                  color: "black",
                }}
              >
                7.
                <span style={{ font: '7pt "Times New Roman"' }}>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
              </span>
            </b>
            <b>
              <span
                style={{
                  fontSize: "12pt",
                  lineHeight: "107%",
                  fontFamily: '"Arial", sans-serif',
                  color: "black",
                }}
              >
                Content
              </span>
            </b>
          </a>
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            marginLeft: "42.55pt",
            textAlign: "justify",
            textIndent: "-42.55pt",
          }}
        >
          <span
            style={{
              fontSize: "12pt",
              lineHeight: "107%",
              fontFamily: '"Arial", sans-serif',
              color: "black",
            }}
          >
            7.1.
            <span style={{ font: '7pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontSize: "12pt",
              lineHeight: "107%",
              fontFamily: '"Arial", sans-serif',
              color: "black",
            }}
          >
            The User grants the Foundation a non-exclusive, worldwide,
            perpetual, irrevocable, royalty-free, sublicensable (through
            multiple tiers) right to exercise any and all Intellectual Property
            Rights the User has in User Content in connection with the
            Foundation's provision, expansion, and promotion of the Services, in
            any media known now or developed in the future. To the fullest
            extent permitted under applicable law, the User waives his/her/its
            right to enforce the Intellectual Property Rights in User Content
            against the Foundation, its assignees, its sublicensees, and their
            assignees in connection with the Foundation's, those assignees', and
            those sublicensees' use of User Content in connection with the
            Foundation's provision, expansion, and promotion of the Services.
          </span>
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            marginLeft: "42.55pt",
            textAlign: "justify",
            textIndent: "-42.55pt",
          }}
        >
          <span
            style={{
              fontSize: "12pt",
              lineHeight: "107%",
              fontFamily: '"Arial", sans-serif',
              color: "black",
            }}
          >
            7.2.
            <span style={{ font: '7pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontSize: "12pt",
              lineHeight: "107%",
              fontFamily: '"Arial", sans-serif',
              color: "black",
            }}
          >
            The User represents and warrants that, for all User Content the User
            provides, the User owns or otherwise controls all necessary rights
            to do so and to meet his/her/its obligations under the Agreement.
            The User represents and warrants that such content is accurate. The
            User represents and warrants that the use of any User Content
            (including derivative works) by the Foundation, its users, or others
            in contract with the Foundation, and in compliance with the
            Agreement, does not and will not infringe any Intellectual Property
            Rights of any third party. the Foundation takes no responsibility
            and assumes no liability for any User Content or the content
            provided by any third party.
          </span>
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            marginLeft: "42.55pt",
            textAlign: "justify",
            textIndent: "-42.55pt",
          }}
        >
          <span
            style={{
              fontSize: "12pt",
              lineHeight: "107%",
              fontFamily: '"Arial", sans-serif',
              color: "black",
            }}
          >
            7.3.
            <span style={{ font: '7pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontSize: "12pt",
              lineHeight: "107%",
              fontFamily: '"Arial", sans-serif',
              color: "black",
            }}
          >
            The Foundation offers product data (including images, descriptions
            and specifications) that are provided by third parties (including
            the Website users). The User may use that content solely in
            his/her/its listings. The Foundation may modify or revoke that
            permission at any time in its sole discretion. The product data
            includes copyrighted, trademarked and other proprietary materials.
            The User agrees not to remove any copyright, proprietary, or
            identification markings in the product data and not to create any
            derivative works based on that data (other than by including the
            data in his/her/its listings).
          </span>
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            marginLeft: "42.55pt",
            textAlign: "justify",
            textIndent: "-42.55pt",
          }}
        >
          <span
            style={{
              fontSize: "12pt",
              lineHeight: "107%",
              fontFamily: '"Arial", sans-serif',
              color: "black",
            }}
          >
            7.4.
            <span style={{ font: '7pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontSize: "12pt",
              lineHeight: "107%",
              fontFamily: '"Arial", sans-serif',
              color: "black",
            }}
          >
            The Foundation, to its best ability, offers reliable item data, but
            does not guarantee that Foundation Content will always be available,
            accurate, complete, and up-to-date. The User agrees that eBay is not
            responsible for examining or warranting the listings or content
            provided by third parties through the Services, and that the User
            will not attempt to hold the Foundation or its data providers liable
            for inaccuracies.
          </span>
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            marginLeft: "42.55pt",
            textAlign: "justify",
            textIndent: "-42.55pt",
          }}
        >
          <span
            style={{
              fontSize: "12pt",
              lineHeight: "107%",
              fontFamily: '"Arial", sans-serif',
              color: "black",
            }}
          >
            7.5.
            <span style={{ font: '7pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontSize: "12pt",
              lineHeight: "107%",
              fontFamily: '"Arial", sans-serif',
              color: "black",
            }}
          >
            The name "Ria Bid" and other marks, logos, designs, and phrases that
            the Foundation uses in connection with the Services are trademarks,
            service marks, or trade dress of the Foundation in Georgia and other
            countries. They may not be used without the express written prior
            permission of the Foundation.
          </span>
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            marginLeft: "42.55pt",
            textAlign: "justify",
            textIndent: "-42.55pt",
          }}
        >
          <span
            style={{
              fontSize: "12pt",
              lineHeight: "107%",
              fontFamily: '"Arial", sans-serif',
              color: "black",
            }}
          >
            &nbsp;
          </span>
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            marginLeft: "42.55pt",
            textAlign: "justify",
            textIndent: "-42.55pt",
          }}
        >
          <a name="_Toc73815240">
            <b>
              <span
                style={{
                  fontSize: "12pt",
                  lineHeight: "107%",
                  fontFamily: '"Arial", sans-serif',
                  color: "black",
                }}
              >
                8.
                <span style={{ font: '7pt "Times New Roman"' }}>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
              </span>
            </b>
            <b>
              <span
                style={{
                  fontSize: "12pt",
                  lineHeight: "107%",
                  fontFamily: '"Arial", sans-serif',
                  color: "black",
                }}
              >
                Fees and Taxes
              </span>
            </b>
          </a>
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            marginLeft: "42.55pt",
            textAlign: "justify",
            textIndent: "-42.55pt",
          }}
        >
          <span
            style={{
              fontSize: "12pt",
              lineHeight: "107%",
              fontFamily: '"Arial", sans-serif',
              color: "black",
            }}
          >
            8.1.
            <span style={{ font: '7pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontSize: "12pt",
              lineHeight: "107%",
              fontFamily: '"Arial", sans-serif',
              color: "black",
            }}
          >
            When listing an item on the Website, the Seller is required to
            indicate the price of the item (hereinafter “Seller’s Price”). The
            Website automatically adds the Foundation's commission in the amount
            of 15% (fifteen percent) to the price indicated by the Seller, which
            represents the commission fee for the Services (hereinafter
            “Commission Fee”). Additionally, the fee for shipping the item will
            be calculated and automatically added by the Website in accordance
            with the shipping rates of the particular carrier (hereinafter
            “Shipping Fee”). The Website will display the final value of the
            item – Seller’s Price plus Commission Fee plus Shipping Fee
            (hereinafter “Final Value Fee”).
          </span>
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            marginLeft: "42.55pt",
            textAlign: "justify",
            textIndent: "-42.55pt",
          }}
        >
          <span
            style={{
              fontSize: "12pt",
              lineHeight: "107%",
              fontFamily: '"Arial", sans-serif',
              color: "black",
            }}
          >
            8.2.
            <span style={{ font: '7pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontSize: "12pt",
              lineHeight: "107%",
              fontFamily: '"Arial", sans-serif',
              color: "black",
            }}
          >
            Proceeds from the Commission Fee is transferred to the Foundation
            for charity purposes.
          </span>
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            marginLeft: "42.55pt",
            textAlign: "justify",
            textIndent: "-42.55pt",
          }}
        >
          <span
            style={{
              fontSize: "12pt",
              lineHeight: "107%",
              fontFamily: '"Arial", sans-serif',
              color: "black",
            }}
          >
            8.3.
            <span style={{ font: '7pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontSize: "12pt",
              lineHeight: "107%",
              fontFamily: '"Arial", sans-serif',
              color: "black",
            }}
          >
            The Foundation shall transfer the proceeds from the Seller’s sold
            items on the bank account indicated by the Seller in the
            registration application, within 1-3 working days after receiving
            the said proceeds.
          </span>
        </p>
        <p
          className="MsoListParagraphCxSpLast"
          style={{
            marginLeft: "42.55pt",
            textAlign: "justify",
            textIndent: "-42.55pt",
          }}
        >
          <span
            style={{
              fontSize: "12pt",
              lineHeight: "107%",
              fontFamily: '"Arial", sans-serif',
              color: "black",
            }}
          >
            8.4.
            <span style={{ font: '7pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontSize: "12pt",
              lineHeight: "107%",
              fontFamily: '"Arial", sans-serif',
              color: "black",
            }}
          >
            All payments described above shall be made through bank transfer and
            are gross from Georgian Taxes. The Foundation is not responsibile to
            pay relevant taxes on behalf of the User.
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginLeft: "42.55pt",
            textAlign: "justify",
            textIndent: "-42.55pt",
          }}
        >
          <span
            style={{
              fontSize: "12pt",
              lineHeight: "107%",
              fontFamily: '"Arial", sans-serif',
              color: "black",
            }}
          >
            &nbsp;
          </span>
        </p>
        <p
          className="MsoListParagraphCxSpFirst"
          style={{
            marginLeft: "42.55pt",
            textAlign: "justify",
            textIndent: "-42.55pt",
          }}
        >
          <a name="_Toc73815241">
            <b>
              <span
                style={{
                  fontSize: "12pt",
                  lineHeight: "107%",
                  fontFamily: '"Arial", sans-serif',
                  color: "black",
                }}
              >
                9.
                <span style={{ font: '7pt "Times New Roman"' }}>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
              </span>
            </b>
            <b>
              <span
                style={{
                  fontSize: "12pt",
                  lineHeight: "107%",
                  fontFamily: '"Arial", sans-serif',
                  color: "black",
                }}
              >
                Shipping
              </span>
            </b>
          </a>
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            marginLeft: "42.55pt",
            textAlign: "justify",
            textIndent: "-42.55pt",
          }}
        >
          <span
            style={{
              fontSize: "12pt",
              lineHeight: "107%",
              fontFamily: '"Arial", sans-serif',
              color: "black",
            }}
          >
            9.1.
            <span style={{ font: '7pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontSize: "12pt",
              lineHeight: "107%",
              fontFamily: '"Arial", sans-serif',
              color: "black",
            }}
          >
            The Seller is responsible for shipping the sold work of art to the
            Foundation and the Foundation, unless agreed otherwise, is
            responsible for shipping the purchased work of art to the Buyer.
          </span>
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            marginLeft: "42.55pt",
            textAlign: "justify",
            textIndent: "-42.55pt",
          }}
        >
          <span
            style={{
              fontSize: "12pt",
              lineHeight: "107%",
              fontFamily: '"Arial", sans-serif',
              color: "black",
            }}
          >
            9.2.
            <span style={{ font: '7pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontSize: "12pt",
              lineHeight: "107%",
              fontFamily: '"Arial", sans-serif',
              color: "black",
            }}
          >
            The Buyer is responsible for all shipping costs and fees excluding
            as defined in Article 9.6. of the Agreement. The Foundation will, at
            the Buyer's expense, either provide packing, handling, insurance
            or/and shipping services or coordinate with shipping agents
            instructed by the Buyer in order to facilitate such services for
            item purchased from the Website.
          </span>
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            marginLeft: "42.55pt",
            textAlign: "justify",
            textIndent: "-42.55pt",
          }}
        >
          <span
            style={{
              fontSize: "12pt",
              lineHeight: "107%",
              fontFamily: '"Arial", sans-serif',
              color: "black",
            }}
          >
            9.3.
            <span style={{ font: '7pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontSize: "12pt",
              lineHeight: "107%",
              fontFamily: '"Arial", sans-serif',
              color: "black",
            }}
          >
            All of the works of art purchased from the Website shall be packaged
            securely in appropriate packaging. The packaging can vary depending
            on the type of work of art purchased.
          </span>
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            marginLeft: "42.55pt",
            textAlign: "justify",
            textIndent: "-42.55pt",
          }}
        >
          <span
            style={{
              fontSize: "12pt",
              lineHeight: "107%",
              fontFamily: '"Arial", sans-serif',
              color: "black",
            }}
          >
            9.4.
            <span style={{ font: '7pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontSize: "12pt",
              lineHeight: "107%",
              fontFamily: '"Arial", sans-serif',
              color: "black",
            }}
          >
            Bulky works of art, such as stretched canvases and framed works of
            art, are typically shipped in either a heavy-duty cardboard box or a
            wooden crate, depending on their destination. In case the work of
            art exceeds 1,5 meters, the Foundation reserves the right
            outstretch/remove the work of art from the canvases and ship it
            rolled in a heavy-duty art tube, in order to keep shipping costs
            low, particularly when shipping internationally. If the Buyer would
            like the work of art to arrive ready to hang, the Buyer can have it
            stretched with the Foundation.
          </span>
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            marginLeft: "42.55pt",
            textAlign: "justify",
            textIndent: "-42.55pt",
          }}
        >
          <span
            style={{
              fontSize: "12pt",
              lineHeight: "107%",
              fontFamily: '"Arial", sans-serif',
              color: "black",
            }}
          >
            9.5.
            <span style={{ font: '7pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontSize: "12pt",
              lineHeight: "107%",
              fontFamily: '"Arial", sans-serif',
              color: "black",
            }}
          >
            For deliveries from Georgia to Russia Federation and its republics,
            Armenia and Azerbaijan the shipping is free of charge and,
            therefore, is not included in Final Value Fee.
          </span>
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            marginLeft: "42.55pt",
            textAlign: "justify",
            textIndent: "-42.55pt",
          }}
        >
          <span
            style={{
              fontSize: "12pt",
              lineHeight: "107%",
              fontFamily: '"Arial", sans-serif',
              color: "black",
            }}
          >
            9.6.
            <span style={{ font: '7pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontSize: "12pt",
              lineHeight: "107%",
              fontFamily: '"Arial", sans-serif',
              color: "black",
            }}
          >
            The shipping time can vary depending on where the Seller is based
            and where in the world the Buyer's delivery address is.
          </span>
        </p>
        <p
          className="MsoListParagraphCxSpLast"
          style={{
            marginLeft: "42.55pt",
            textAlign: "justify",
            textIndent: "-42.55pt",
          }}
        >
          <span
            style={{
              fontSize: "12pt",
              lineHeight: "107%",
              fontFamily: '"Arial", sans-serif',
              color: "black",
            }}
          >
            9.7.
            <span style={{ font: '7pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontSize: "12pt",
              lineHeight: "107%",
              fontFamily: '"Arial", sans-serif',
              color: "black",
            }}
          >
            COVID-19 SHIPPING DELAY NOTICE - due to these long and universal
            delays associated with the COVID-19 pandemic and the overwhelming
            demand for deliveries across the world, as well as modified
            practices by carriers the User should anticipate parcel delays for
            the foreseeable future. Therefore, the Foundation is unable to give
            the User an accurate estimate of the item's arrival date.
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginLeft: "42.55pt",
            textAlign: "justify",
            textIndent: "-42.55pt",
          }}
        >
          <b>
            <span
              style={{
                fontSize: "12pt",
                lineHeight: "107%",
                fontFamily: '"Arial", sans-serif',
                color: "black",
              }}
            >
              &nbsp;
            </span>
          </b>
        </p>
        <p
          className="MsoListParagraphCxSpFirst"
          style={{
            marginLeft: "42.55pt",
            textAlign: "justify",
            textIndent: "-42.55pt",
          }}
        >
          <a name="_Toc73815242">
            <b>
              <span
                style={{
                  fontSize: "12pt",
                  lineHeight: "107%",
                  fontFamily: '"Arial", sans-serif',
                  color: "black",
                }}
              >
                10.
                <span style={{ font: '7pt "Times New Roman"' }}>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
              </span>
            </b>
            <b>
              <span
                style={{
                  fontSize: "12pt",
                  lineHeight: "107%",
                  fontFamily: '"Arial", sans-serif',
                  color: "black",
                }}
              >
                Disclaimer of Warranties; Limitation of Liability
              </span>
            </b>
          </a>
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            marginLeft: "42.55pt",
            textAlign: "justify",
            textIndent: "-42.55pt",
          }}
        >
          <span
            style={{
              fontSize: "12pt",
              lineHeight: "107%",
              fontFamily: '"Arial", sans-serif',
              color: "black",
            }}
          >
            10.1.
            <span style={{ font: '7pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontSize: "12pt",
              lineHeight: "107%",
              fontFamily: '"Arial", sans-serif',
              color: "black",
            }}
          >
            Each Party bears responsibility for non-fulfillment or improper
            fulfillment of his/her/its obligations provided that this is caused
            by his/her/its faulty actions and/or negligence.
          </span>
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            marginLeft: "42.55pt",
            textAlign: "justify",
            textIndent: "-42.55pt",
          }}
        >
          <span
            style={{
              fontSize: "12pt",
              lineHeight: "107%",
              fontFamily: '"Arial", sans-serif',
              color: "black",
            }}
          >
            10.2.
            <span style={{ font: '7pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontSize: "12pt",
              lineHeight: "107%",
              fontFamily: '"Arial", sans-serif',
              color: "black",
            }}
          >
            The User acknowledges and agrees that his/her/its access to and use
            of the Services is at his/her/its own risk and that the Services are
            provided on an "as is" and "as available" basis. The Foundation
            makes no representations or warranties of any kind, express or
            implied, with respect to the Services or any Content, including any
            warranties of merchantability, fitness for a particular purpose,
            title, non-infringement, and quiet enjoyment, and any warranties
            arising out of course of dealing or usage of trade, all of which the
            Foundation and its officers, owners, directors, consultants, agents,
            and employees (collectively, the "Foundation Parties") expressly
            disclaim to the fullest extent permitted by applicable Regulations.
            The Foundation Parties make no representations or warranties of any
            kind, express or implied, that the Services or any Content will meet
            the User's requirements or be available, accessible, uninterrupted,
            timely, accurate, reliable, complete, free of errors, safe, secure,
            or free of viruses or other harmful components, or that the use of
            the Services in any way will give rise to any specific results.
            Applicable Regulations may not permit the exclusion of implied
            warranties, so some of the exclusions in this Article may not apply
            to the User.
          </span>
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            marginLeft: "42.55pt",
            textAlign: "justify",
            textIndent: "-42.55pt",
          }}
        >
          <span
            style={{
              fontSize: "12pt",
              lineHeight: "107%",
              fontFamily: '"Arial", sans-serif',
              color: "black",
            }}
          >
            10.3.
            <span style={{ font: '7pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontSize: "12pt",
              lineHeight: "107%",
              fontFamily: '"Arial", sans-serif',
              color: "black",
            }}
          >
            If the use of the Services results in the need for servicing or
            replacing any equipment or data, the Foundation Parties will not be
            responsible for those costs or losses.
          </span>
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            marginLeft: "42.55pt",
            textAlign: "justify",
            textIndent: "-42.55pt",
          }}
        >
          <span
            style={{
              fontSize: "12pt",
              lineHeight: "107%",
              fontFamily: '"Arial", sans-serif',
              color: "black",
            }}
          >
            10.4.
            <span style={{ font: '7pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontSize: "12pt",
              lineHeight: "107%",
              fontFamily: '"Arial", sans-serif',
              color: "black",
            }}
          >
            No advice or information (whether oral or written) obtained from the
            Foundation Parties, or through the Services or any Content, will
            create any warranty by us not expressly stated in this Agreement or
            in a separate written agreement between the Parties.
          </span>
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            marginLeft: "42.55pt",
            textAlign: "justify",
            textIndent: "-42.55pt",
          }}
        >
          <span
            style={{
              fontSize: "12pt",
              lineHeight: "107%",
              fontFamily: '"Arial", sans-serif',
              color: "black",
            }}
          >
            10.5.
            <span style={{ font: '7pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontSize: "12pt",
              lineHeight: "107%",
              fontFamily: '"Arial", sans-serif',
              color: "black",
            }}
          >
            Unless otherwise agreed to or noted in writing, the Foundation does
            not own, take title to, have possession of, or sell the property
            listed, marketed, offered, or sold on or in connection with the
            Services (whether online or offline). The Foundation does not sell
            property on behalf of the Seller. All property is offered for sale
            and sold directly from the Seller to the Buyer, and the Foundation
            is not a party to any sale. The Foundation does not transfer and is
            not responsible for transferring, legal ownership or physical
            possession of property from the Seller to the Buyer.
          </span>
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            marginLeft: "42.55pt",
            textAlign: "justify",
            textIndent: "-42.55pt",
          }}
        >
          <span
            style={{
              fontSize: "12pt",
              lineHeight: "107%",
              fontFamily: '"Arial", sans-serif',
              color: "black",
            }}
          >
            10.6.
            <span style={{ font: '7pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontSize: "12pt",
              lineHeight: "107%",
              fontFamily: '"Arial", sans-serif',
              color: "black",
            }}
          >
            The Foundation may provide rules for using the Services, but the
            Foundation Parties do not endorse or control and are not responsible
            for the conduct (whether online or offline) of any user and/or third
            party on or in connection with the Services.
          </span>
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            marginLeft: "42.55pt",
            textAlign: "justify",
            textIndent: "-42.55pt",
          }}
        >
          <span
            style={{
              fontSize: "12pt",
              lineHeight: "107%",
              fontFamily: '"Arial", sans-serif',
              color: "black",
            }}
          >
            10.7.
            <span style={{ font: '7pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontSize: "12pt",
              lineHeight: "107%",
              fontFamily: '"Arial", sans-serif',
              color: "black",
            }}
          >
            To the fullest extent permitted by applicable Regulation, the User
            release the Foundation Parties from all claims, demands, suits,
            damages (actual and consequential), losses, liabilities, and
            expenses (including attorneys' fees), of every kind and nature,
            known and unknown, suspected and unsuspected, disclosed and
            undisclosed, arising out of or in connection with: (i) any disputes
            between the User and one (1) or more other users or other third
            parties resulting directly or indirectly from the User's use of the
            Services; (ii) any User Content or conduct, performance or
            non-performance (whether online or offline) of any other user or
            other third party in any way connected with the Services, including
            any defamatory, misleading, offensive or unlawful conduct or User
            Content, or any unauthorized access to or use of the Services, the
            User's account or User Content and/or information; (iii) any work of
            art or other item exhibited, listed, marketed, offered for sale, or
            sold (whether online or offline) by any other user or other third
            party on, through, or in any way connected with the Services; or
            (iv) any communications, interactions or meetings (whether in
            person, online or otherwise) between the User and one (1) or more
            other users or other third parties resulting directly or indirectly
            from the User's use of the Services. The User expressly waives any
            benefits or protections, whether statutory or otherwise, that would
            otherwise limit the coverage of this release to include only those
            claims which the User may know or suspect to exist in the User's
            favor at the time of entering into this release.
          </span>
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            marginLeft: "42.55pt",
            textAlign: "justify",
            textIndent: "-42.55pt",
          }}
        >
          <span
            style={{
              fontSize: "12pt",
              lineHeight: "107%",
              fontFamily: '"Arial", sans-serif',
              color: "black",
            }}
          >
            10.8.
            <span style={{ font: '7pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontSize: "12pt",
              lineHeight: "107%",
              fontFamily: '"Arial", sans-serif',
              color: "black",
            }}
          >
            Under no circumstances will any of the Foundation Parties be liable
            to the User or any third party for any indirect, incidental,
            exemplary, punitive, special, or consequential damages, any personal
            or bodily injury or emotional distress, or any loss of profits,
            revenue, business, data, use, goodwill or other intangible losses,
            arising out of or in connection with: (i) this Agreement; (ii) the
            Services; (iii) the use of or inability to use the Services; (iv)
            any conduct, performance or non-performance (whether online or
            offline) of any other user or other third party on or in connection
            with the Services; or (v) any content, goods or services made
            available on, through, or in any way connected with the Services
            (whether online or offline) by us or any other user or other third
            party.
          </span>
        </p>
        <p
          className="MsoListParagraphCxSpLast"
          style={{
            marginLeft: "42.55pt",
            textAlign: "justify",
            textIndent: "-42.55pt",
          }}
        >
          <span
            style={{
              fontSize: "12pt",
              lineHeight: "107%",
              fontFamily: '"Arial", sans-serif',
              color: "black",
            }}
          >
            10.9.
            <span style={{ font: '7pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontSize: "12pt",
              lineHeight: "107%",
              fontFamily: '"Arial", sans-serif',
              color: "black",
            }}
          >
            The exclusions and limitations of liability provided above in
            Article 9.8 apply to all claims, whether based on warranty,
            contract, statute, tort (including negligence), strict liability, or
            any other legal theory, whether or not any of the Foundation Parties
            have been advised of or should have known of the possibility of such
            damage or loss, and even if a remedy set forth in these terms is
            found to have failed of its essential purpose. Applicable Regulation
            may not permit the exclusion or limitation of liability for
            consequential or incidental damages, so some of the exclusions and
            limitations provided above in Article 9.8 may not apply to the User,
            in which case the liability of the Foundation Parties will be
            limited to the fullest extent permitted by applicable Regulation.
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginLeft: "42.55pt",
            textAlign: "justify",
            textIndent: "-42.55pt",
          }}
        >
          <span
            style={{
              fontSize: "12pt",
              lineHeight: "107%",
              fontFamily: '"Arial", sans-serif',
              color: "black",
            }}
          >
            &nbsp;
          </span>
        </p>
        <p
          className="MsoListParagraphCxSpFirst"
          style={{
            marginLeft: "42.55pt",
            textAlign: "justify",
            textIndent: "-42.55pt",
          }}
        >
          <a name="_Toc73815243">
            <b>
              <span
                style={{
                  fontSize: "12pt",
                  lineHeight: "107%",
                  fontFamily: '"Arial", sans-serif',
                  color: "black",
                }}
              >
                11.
                <span style={{ font: '7pt "Times New Roman"' }}>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
              </span>
            </b>
            <b>
              <span
                style={{
                  fontSize: "12pt",
                  lineHeight: "107%",
                  fontFamily: '"Arial", sans-serif',
                  color: "black",
                }}
              >
                Intellectual Property
              </span>
            </b>
          </a>
        </p>
        <p
          className="MsoListParagraphCxSpLast"
          style={{
            marginLeft: "42.55pt",
            textAlign: "justify",
            textIndent: "-42.55pt",
          }}
        >
          <span
            style={{
              fontSize: "12pt",
              lineHeight: "107%",
              fontFamily: '"Arial", sans-serif',
              color: "black",
            }}
          >
            11.1.
            <span style={{ font: '7pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontSize: "12pt",
              lineHeight: "107%",
              fontFamily: '"Arial", sans-serif',
              color: "black",
            }}
          >
            The Foundation owns and retains, solely and exclusively, all rights,
            title, and interest in and to the Services, including but not
            limited to all copyrights, trademark rights, trade secrets, patent
            rights, moral rights, database rights, and other intellectual
            property rights therein. Except for the limited use of the Services
            that the Foundation grants to the User under this Agreement, nothing
            in this Agreement grants the User any license or permission to use
            any of the Foundation's trademarks, service marks or logos in any
            manner, or any other license or permission under any copyright,
            trademark or other intellectual property rights of the Foundation or
            any third party, whether by implication, estoppel or otherwise. The
            Foundation reserves all rights not expressly granted to the User in
            this Agreement or in a separate written agreement between the
            Parties.
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginLeft: "42.55pt",
            textAlign: "justify",
            textIndent: "-42.55pt",
          }}
        >
          <span
            style={{
              fontSize: "12pt",
              lineHeight: "107%",
              fontFamily: '"Arial", sans-serif',
              color: "black",
              background: "aqua",
            }}
          >
            &nbsp;
          </span>
        </p>
        <p
          className="MsoListParagraphCxSpFirst"
          style={{
            marginLeft: "42.55pt",
            textAlign: "justify",
            textIndent: "-42.55pt",
          }}
        >
          <a name="_Toc73815244">
            <b>
              <span
                style={{
                  fontSize: "12pt",
                  lineHeight: "107%",
                  fontFamily: '"Arial", sans-serif',
                  color: "black",
                }}
              >
                12.
                <span style={{ font: '7pt "Times New Roman"' }}>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
              </span>
            </b>
            <b>
              <span
                style={{
                  fontSize: "12pt",
                  lineHeight: "107%",
                  fontFamily: '"Arial", sans-serif',
                  color: "black",
                }}
              >
                Confidentiality
              </span>
            </b>
          </a>
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            marginLeft: "42.55pt",
            textAlign: "justify",
            textIndent: "-42.55pt",
          }}
        >
          <span
            style={{
              fontSize: "12pt",
              lineHeight: "107%",
              fontFamily: '"Arial", sans-serif',
              color: "black",
            }}
          >
            12.1.
            <span style={{ font: '7pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontSize: "12pt",
              lineHeight: "107%",
              fontFamily: '"Arial", sans-serif',
              color: "black",
            }}
          >
            The Parties of this Agreement will undertake all measures to prevent
            access of the third Parties to the Confidential Information.
          </span>
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            marginLeft: "42.55pt",
            textAlign: "justify",
            textIndent: "-42.55pt",
          }}
        >
          <span
            style={{
              fontSize: "12pt",
              lineHeight: "107%",
              fontFamily: '"Arial", sans-serif',
              color: "black",
            }}
          >
            12.2.
            <span style={{ font: '7pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontSize: "12pt",
              lineHeight: "107%",
              fontFamily: '"Arial", sans-serif',
              color: "black",
            }}
          >
            The Party has to obtain written permit of the other Party in order
            to disclose the Confidential Information received from such Party.
            Disclosure of the information will mean its dissemination to the
            third Parties.
          </span>
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            marginLeft: "42.55pt",
            textAlign: "justify",
            textIndent: "-42.55pt",
          }}
        >
          <span
            style={{
              fontSize: "12pt",
              lineHeight: "107%",
              fontFamily: '"Arial", sans-serif',
              color: "black",
            }}
          >
            12.3.
            <span style={{ font: '7pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontSize: "12pt",
              lineHeight: "107%",
              fontFamily: '"Arial", sans-serif',
              color: "black",
            }}
          >
            The information might be disclosed if:
          </span>
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            marginLeft: "63.8pt",
            textAlign: "justify",
            textIndent: "-14.2pt",
          }}
        >
          <span
            style={{
              fontSize: "12pt",
              lineHeight: "107%",
              fontFamily: '"Arial", sans-serif',
              color: "black",
            }}
          >
            a.<span style={{ font: '7pt "Times New Roman"' }}>&nbsp; </span>
          </span>
          <span
            style={{
              fontSize: "12pt",
              lineHeight: "107%",
              fontFamily: '"Arial", sans-serif',
              color: "black",
            }}
          >
            Disclosure requested by the law.
          </span>
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            marginLeft: "63.8pt",
            textAlign: "justify",
            textIndent: "-14.2pt",
          }}
        >
          <span
            style={{
              fontSize: "12pt",
              lineHeight: "107%",
              fontFamily: '"Arial", sans-serif',
              color: "black",
            }}
          >
            b.<span style={{ font: '7pt "Times New Roman"' }}>&nbsp; </span>
          </span>
          <span
            style={{
              fontSize: "12pt",
              lineHeight: "107%",
              fontFamily: '"Arial", sans-serif',
              color: "black",
            }}
          >
            The information will be disclosed to the lawyers, insurers and/or
            other persons who are or will be engaged in the dispute between the
            Client and the User.
          </span>
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            marginLeft: "63.8pt",
            textAlign: "justify",
            textIndent: "-14.2pt",
          }}
        >
          <span
            style={{
              fontSize: "12pt",
              lineHeight: "107%",
              fontFamily: '"Arial", sans-serif',
              color: "black",
            }}
          >
            c.
            <span style={{ font: '7pt "Times New Roman"' }}>&nbsp;&nbsp;</span>
          </span>
          <span
            style={{
              fontSize: "12pt",
              lineHeight: "107%",
              fontFamily: '"Arial", sans-serif',
              color: "black",
            }}
          >
            Information will be disclosed to the Subcontractors engaged in
            service provision. In such case these firms will be restricted with
            the same conditions with respect to the confidential information
            disclosure as those applied to the User.
          </span>
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            marginLeft: "42.55pt",
            textAlign: "justify",
            textIndent: "-42.55pt",
          }}
        >
          <span
            style={{
              fontSize: "12pt",
              lineHeight: "107%",
              fontFamily: '"Arial", sans-serif',
              color: "black",
            }}
          >
            12.4.
            <span style={{ font: '7pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontSize: "12pt",
              lineHeight: "107%",
              fontFamily: '"Arial", sans-serif',
              color: "black",
            }}
          >
            This clause shall survive termination of this Agreement.
          </span>
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            marginLeft: "42.55pt",
            textAlign: "justify",
            textIndent: "-42.55pt",
          }}
        >
          <span
            style={{
              fontSize: "12pt",
              lineHeight: "107%",
              fontFamily: '"Arial", sans-serif',
              color: "black",
            }}
          >
            12.5.
            <span style={{ font: '7pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontSize: "12pt",
              lineHeight: "107%",
              fontFamily: '"Arial", sans-serif',
              color: "black",
            }}
          >
            Confidential information does not cover:
          </span>
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            marginLeft: "63.8pt",
            textAlign: "justify",
            textIndent: "-14.2pt",
          }}
        >
          <span
            style={{
              fontSize: "12pt",
              lineHeight: "107%",
              fontFamily: '"Arial", sans-serif',
              color: "black",
            }}
          >
            a.<span style={{ font: '7pt "Times New Roman"' }}>&nbsp; </span>
          </span>
          <span
            style={{
              fontSize: "12pt",
              lineHeight: "107%",
              fontFamily: '"Arial", sans-serif',
              color: "black",
            }}
          >
            Public information or/and documentation or information/documentation
            which will become public in the future;
          </span>
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            marginLeft: "63.8pt",
            textAlign: "justify",
            textIndent: "-14.2pt",
          }}
        >
          <span
            style={{
              fontSize: "12pt",
              lineHeight: "107%",
              fontFamily: '"Arial", sans-serif',
              color: "black",
            }}
          >
            b.<span style={{ font: '7pt "Times New Roman"' }}>&nbsp; </span>
          </span>
          <span
            style={{
              fontSize: "12pt",
              lineHeight: "107%",
              fontFamily: '"Arial", sans-serif',
              color: "black",
            }}
          >
            Information received from the third party who is not bound with the
            obligation of confidentiality before the Parties regarding such
            information;
          </span>
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            marginLeft: "63.8pt",
            textAlign: "justify",
            textIndent: "-14.2pt",
          }}
        >
          <span
            style={{
              fontSize: "12pt",
              lineHeight: "107%",
              fontFamily: '"Arial", sans-serif',
              color: "black",
            }}
          >
            c.
            <span style={{ font: '7pt "Times New Roman"' }}>&nbsp;&nbsp;</span>
          </span>
          <span
            style={{
              fontSize: "12pt",
              lineHeight: "107%",
              fontFamily: '"Arial", sans-serif',
              color: "black",
            }}
          >
            Information disclosure of which is envisaged by the legislation of
            Georgia.
          </span>
        </p>
        <p
          className="MsoListParagraphCxSpLast"
          style={{
            marginLeft: "42.55pt",
            textAlign: "justify",
            textIndent: "-42.55pt",
          }}
        >
          <span
            style={{
              fontSize: "12pt",
              lineHeight: "107%",
              fontFamily: '"Arial", sans-serif',
              color: "black",
            }}
          >
            12.6.
            <span style={{ font: '7pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontSize: "12pt",
              lineHeight: "107%",
              fontFamily: '"Arial", sans-serif',
              color: "black",
            }}
          >
            In case of termination of this Agreement, any documentation deemed
            as confidential shall be returned to the Parties.
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginLeft: "42.55pt",
            textAlign: "justify",
            textIndent: "-42.55pt",
          }}
        >
          <span
            style={{
              fontSize: "12pt",
              lineHeight: "107%",
              fontFamily: '"Arial", sans-serif',
              color: "black",
            }}
          >
            &nbsp;
          </span>
        </p>
        <p
          className="MsoListParagraphCxSpFirst"
          style={{
            marginLeft: "42.55pt",
            textAlign: "justify",
            textIndent: "-42.55pt",
          }}
        >
          <a name="_Toc73815245">
            <b>
              <span
                style={{
                  fontSize: "12pt",
                  lineHeight: "107%",
                  fontFamily: '"Arial", sans-serif',
                  color: "black",
                }}
              >
                13.
                <span style={{ font: '7pt "Times New Roman"' }}>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
              </span>
            </b>
            <b>
              <span
                style={{
                  fontSize: "12pt",
                  lineHeight: "107%",
                  fontFamily: '"Arial", sans-serif',
                  color: "black",
                }}
              >
                Indemnification
              </span>
            </b>
          </a>
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            marginLeft: "42.55pt",
            textAlign: "justify",
            textIndent: "-42.55pt",
          }}
        >
          <span
            style={{
              fontSize: "12pt",
              lineHeight: "107%",
              fontFamily: '"Arial", sans-serif',
              color: "black",
            }}
          >
            13.1.
            <span style={{ font: '7pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontSize: "12pt",
              lineHeight: "107%",
              fontFamily: '"Arial", sans-serif',
              color: "black",
            }}
          >
            The User agrees to indemnify, defend, and hold harmless the
            Foundation and its officers, directors, agents, and employees from
            and against any and all third-party claims, demands, suits, and
            proceedings, and all related damages, losses, judgments,
            liabilities, and expenses (including reasonable attorneys' fees)
            arising out of or related to: (i) the User's breach of any of the
            Agreement and other applicable Regulation; (ii) the User Content;
            (iii) any misrepresentation made by the User; or (iv) the User's
            violation of any law or the rights of any third party, including any
            intellectual property right, privacy right, or publicity right.
          </span>
        </p>
        <p
          className="MsoListParagraphCxSpLast"
          style={{
            marginLeft: "42.55pt",
            textAlign: "justify",
            textIndent: "-42.55pt",
          }}
        >
          <span
            style={{
              fontSize: "12pt",
              lineHeight: "107%",
              fontFamily: '"Arial", sans-serif',
              color: "black",
            }}
          >
            13.2.
            <span style={{ font: '7pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontSize: "12pt",
              lineHeight: "107%",
              fontFamily: '"Arial", sans-serif',
              color: "black",
            }}
          >
            The Foundation reserves the right to assume the exclusive defense
            and control of any matter subject to indemnification by the User, in
            which case the User agrees to cooperate as fully as reasonably
            required by us in conducting such defense. In any case, the User
            agrees not to settle any such matter without the Foundation's prior
            written consent. The User's indemnification and defense obligations
            provided in this Agreement will survive the Agreement and the User's
            use of the Services.
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginLeft: "42.55pt",
            textAlign: "justify",
            textIndent: "-42.55pt",
          }}
        >
          <b>
            <span
              style={{
                fontSize: "12pt",
                lineHeight: "107%",
                fontFamily: '"Arial", sans-serif',
                color: "black",
              }}
            >
              &nbsp;
            </span>
          </b>
        </p>
        <p
          className="MsoListParagraphCxSpFirst"
          style={{
            marginLeft: "42.55pt",
            textAlign: "justify",
            textIndent: "-42.55pt",
          }}
        >
          <a name="_Toc315959974" />
          <a name="_Toc73815246" />
          <a name="_Toc318634026">
            <b>
              <span
                style={{
                  fontSize: "12pt",
                  lineHeight: "107%",
                  fontFamily: '"Arial", sans-serif',
                  color: "black",
                }}
              >
                14.
                <span style={{ font: '7pt "Times New Roman"' }}>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
              </span>
            </b>
            <b>
              <span
                style={{
                  fontSize: "12pt",
                  lineHeight: "107%",
                  fontFamily: '"Arial", sans-serif',
                  color: "black",
                }}
              >
                G
              </span>
            </b>
          </a>
          <b>
            <span
              style={{
                fontSize: "12pt",
                lineHeight: "107%",
                fontFamily: '"Arial", sans-serif',
                color: "black",
              }}
            >
              overning Law and Dispute Resolution
            </span>
          </b>
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            marginLeft: "42.55pt",
            textAlign: "justify",
            textIndent: "-42.55pt",
          }}
        >
          <span
            style={{
              fontSize: "12pt",
              lineHeight: "107%",
              fontFamily: '"Arial", sans-serif',
              color: "black",
            }}
          >
            14.1.
            <span style={{ font: '7pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontSize: "12pt",
              lineHeight: "107%",
              fontFamily: '"Arial", sans-serif',
              color: "black",
            }}
          >
            This Agreement is governed and interpreted according to Georgian
            laws and regulations.
          </span>
        </p>
        <p
          className="MsoListParagraphCxSpLast"
          style={{
            marginLeft: "42.55pt",
            textAlign: "justify",
            textIndent: "-42.55pt",
          }}
        >
          <span
            style={{
              fontSize: "12pt",
              lineHeight: "107%",
              fontFamily: '"Arial", sans-serif',
              color: "black",
            }}
          >
            14.2.
            <span style={{ font: '7pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontSize: "12pt",
              lineHeight: "107%",
              fontFamily: '"Arial", sans-serif',
              color: "black",
            }}
          >
            Any and all disputes and disagreement that might arise between the
            Parties should be solved through amicable negotiations. If the
            Parties fail to solve the dispute through negotiations, they may
            apply to the courts of Georgia.
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginLeft: "42.55pt",
            textAlign: "justify",
            textIndent: "-42.55pt",
          }}
        >
          <span
            style={{
              fontSize: "12pt",
              lineHeight: "107%",
              fontFamily: '"Arial", sans-serif',
              color: "black",
            }}
          >
            &nbsp;
          </span>
        </p>
        <p
          className="MsoListParagraphCxSpFirst"
          style={{
            marginLeft: "42.55pt",
            textAlign: "justify",
            textIndent: "-42.55pt",
          }}
        >
          <a name="_Toc73815247">
            <b>
              <span
                style={{
                  fontSize: "12pt",
                  lineHeight: "107%",
                  fontFamily: '"Arial", sans-serif',
                  color: "black",
                }}
              >
                15.
                <span style={{ font: '7pt "Times New Roman"' }}>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
              </span>
            </b>
            <b>
              <span
                style={{
                  fontSize: "12pt",
                  lineHeight: "107%",
                  fontFamily: '"Arial", sans-serif',
                  color: "black",
                }}
              >
                Terms and Termination
              </span>
            </b>
          </a>
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            marginLeft: "42.55pt",
            textAlign: "justify",
            textIndent: "-42.55pt",
          }}
        >
          <span
            style={{
              fontSize: "12pt",
              lineHeight: "107%",
              fontFamily: '"Arial", sans-serif',
              color: "black",
            }}
          >
            15.1.
            <span style={{ font: '7pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontSize: "12pt",
              lineHeight: "107%",
              fontFamily: '"Arial", sans-serif',
              color: "black",
            }}
          >
            This Agreement becomes effective upon its execution and remains
            valid until full implementation of the contractual obligations by
            the Parties.
          </span>
        </p>
        <p
          className="MsoListParagraphCxSpLast"
          style={{
            marginLeft: "42.55pt",
            textAlign: "justify",
            textIndent: "-42.55pt",
          }}
        >
          <span
            style={{
              fontSize: "12pt",
              lineHeight: "107%",
              fontFamily: '"Arial", sans-serif',
              color: "black",
            }}
          >
            15.2.
            <span style={{ font: '7pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontSize: "12pt",
              lineHeight: "107%",
              fontFamily: '"Arial", sans-serif',
              color: "black",
            }}
          >
            Either Party is entitled to unilaterally with the written
            notification given to the other Party terminate this Agreement with
            immediate effect if the other Party systematically and/or materially
            breaches the contractual obligations.
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginLeft: "42.55pt",
            textAlign: "justify",
            textIndent: "-42.55pt",
          }}
        >
          <span
            style={{
              fontSize: "12pt",
              lineHeight: "107%",
              fontFamily: '"Arial", sans-serif',
              color: "black",
            }}
          >
            &nbsp;
          </span>
        </p>
        <p
          className="MsoListParagraphCxSpFirst"
          style={{
            marginLeft: "42.55pt",
            textAlign: "justify",
            textIndent: "-42.55pt",
          }}
        >
          <a name="_Toc73815248">
            <b>
              <span
                style={{
                  fontSize: "12pt",
                  lineHeight: "107%",
                  fontFamily: '"Arial", sans-serif',
                  color: "black",
                }}
              >
                16.
                <span style={{ font: '7pt "Times New Roman"' }}>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
              </span>
            </b>
            <b>
              <span
                style={{
                  fontSize: "12pt",
                  lineHeight: "107%",
                  fontFamily: '"Arial", sans-serif',
                  color: "black",
                }}
              >
                Communication and Correspondence
              </span>
            </b>
          </a>
        </p>
        <p
          className="MsoListParagraphCxSpLast"
          style={{
            marginLeft: "42.55pt",
            textAlign: "justify",
            textIndent: "-42.55pt",
          }}
        >
          <span
            style={{
              fontSize: "12pt",
              lineHeight: "107%",
              fontFamily: '"Arial", sans-serif',
              color: "black",
            }}
          >
            16.1.
            <span style={{ font: '7pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontSize: "12pt",
              lineHeight: "107%",
              fontFamily: '"Arial", sans-serif',
              color: "black",
            }}
          >
            In case of any questions about this Agreement or the Services, the
            User shall email the Foundation at:{" "}
          </span>
          <a href="mailto:help@riabid.ge">
            <span
              style={{
                fontSize: "12pt",
                lineHeight: "107%",
                fontFamily: '"Arial", sans-serif',
              }}
            >
              help@riabid.ge
            </span>
          </a>
          <span
            style={{
              fontSize: "12pt",
              lineHeight: "107%",
              fontFamily: '"Arial", sans-serif',
              color: "black",
            }}
          >
            or{" "}
          </span>
          <a href="mailto:noreply@riabid.ge">
            <span
              style={{
                fontSize: "12pt",
                lineHeight: "107%",
                fontFamily: '"Arial", sans-serif',
              }}
            >
              noreply@riabid.ge
            </span>
          </a>
          <span
            style={{
              fontSize: "12pt",
              lineHeight: "107%",
              fontFamily: '"Arial", sans-serif',
              color: "black",
            }}
          >
            ; &nbsp;or send a protected post on the following address: 17 Ioane
            Shavteli Street, 0105 Tbilisi, Georgia.
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginLeft: "42.55pt",
            textAlign: "justify",
            textIndent: "-42.55pt",
          }}
        >
          <b>
            <span
              style={{
                fontSize: "12pt",
                lineHeight: "107%",
                fontFamily: '"Arial", sans-serif',
                color: "black",
              }}
            >
              &nbsp;
            </span>
          </b>
        </p>
        <p
          className="MsoListParagraphCxSpFirst"
          style={{
            marginLeft: "42.55pt",
            textAlign: "justify",
            textIndent: "-42.55pt",
          }}
        >
          <a name="_Toc73815249">
            <b>
              <span
                style={{
                  fontSize: "12pt",
                  lineHeight: "107%",
                  fontFamily: '"Arial", sans-serif',
                  color: "black",
                }}
              >
                17.
                <span style={{ font: '7pt "Times New Roman"' }}>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
              </span>
            </b>
            <b>
              <span
                style={{
                  fontSize: "12pt",
                  lineHeight: "107%",
                  fontFamily: '"Arial", sans-serif',
                  color: "black",
                }}
              >
                Amendments
              </span>
            </b>
          </a>
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            marginLeft: "42.55pt",
            textAlign: "justify",
            textIndent: "-42.55pt",
          }}
        >
          <span
            style={{
              fontSize: "12pt",
              lineHeight: "107%",
              fontFamily: '"Arial", sans-serif',
              color: "black",
            }}
          >
            17.1.
            <span style={{ font: '7pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontSize: "12pt",
              lineHeight: "107%",
              fontFamily: '"Arial", sans-serif',
              color: "black",
            }}
          >
            The Foundation reserves the right to amend the Agreement at any time
            by posting the amended terms on the Website.
          </span>
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            marginLeft: "42.55pt",
            textAlign: "justify",
            textIndent: "-42.55pt",
          }}
        >
          <span
            style={{
              fontSize: "12pt",
              lineHeight: "107%",
              fontFamily: '"Arial", sans-serif',
              color: "black",
            }}
          >
            17.2.
            <span style={{ font: '7pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontSize: "12pt",
              lineHeight: "107%",
              fontFamily: '"Arial", sans-serif',
              color: "black",
            }}
          >
            The Foundation's right to amend the Agreement includes the right to
            modify, add to, or remove terms in the Agreement.
          </span>
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            marginLeft: "42.55pt",
            textAlign: "justify",
            textIndent: "-42.55pt",
          }}
        >
          <span
            style={{
              fontSize: "12pt",
              lineHeight: "107%",
              fontFamily: '"Arial", sans-serif',
              color: "black",
            }}
          >
            17.3.
            <span style={{ font: '7pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontSize: "12pt",
              lineHeight: "107%",
              fontFamily: '"Arial", sans-serif',
              color: "black",
            }}
          >
            The Foundation will provide the User 30 days' notice by posting the
            amended terms on the Website.
          </span>
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            marginLeft: "42.55pt",
            textAlign: "justify",
            textIndent: "-42.55pt",
          }}
        >
          <span
            style={{
              fontSize: "12pt",
              lineHeight: "107%",
              fontFamily: '"Arial", sans-serif',
              color: "black",
            }}
          >
            17.4.
            <span style={{ font: '7pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontSize: "12pt",
              lineHeight: "107%",
              fontFamily: '"Arial", sans-serif',
              color: "black",
            }}
          >
            The Foundation will notify the User through the text message or/and
            by email.
          </span>
        </p>
        <p
          className="MsoListParagraphCxSpLast"
          style={{
            marginLeft: "42.55pt",
            textAlign: "justify",
            textIndent: "-42.55pt",
          }}
        >
          <span
            style={{
              fontSize: "12pt",
              lineHeight: "107%",
              fontFamily: '"Arial", sans-serif',
              color: "black",
            }}
          >
            17.5.
            <span style={{ font: '7pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontSize: "12pt",
              lineHeight: "107%",
              fontFamily: '"Arial", sans-serif',
              color: "black",
            }}
          >
            The User's continued access or use of the Services constitutes the
            User's acceptance of the amended terms.
          </span>
        </p>
        <p
          className="MsoNormal"
          style={{
            marginLeft: "42.55pt",
            textAlign: "justify",
            textIndent: "-42.55pt",
          }}
        >
          <span
            style={{
              fontSize: "12pt",
              lineHeight: "107%",
              fontFamily: '"Arial", sans-serif',
              color: "black",
            }}
          >
            &nbsp;
          </span>
        </p>
        <p
          className="MsoListParagraphCxSpFirst"
          style={{
            marginLeft: "42.55pt",
            textAlign: "justify",
            textIndent: "-42.55pt",
          }}
        >
          <a name="_Toc73815250">
            <b>
              <span
                style={{
                  fontSize: "12pt",
                  lineHeight: "107%",
                  fontFamily: '"Arial", sans-serif',
                  color: "black",
                }}
              >
                18.
                <span style={{ font: '7pt "Times New Roman"' }}>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </span>
              </span>
            </b>
            <b>
              <span
                style={{
                  fontSize: "12pt",
                  lineHeight: "107%",
                  fontFamily: '"Arial", sans-serif',
                  color: "black",
                }}
              >
                Miscellaneous
              </span>
            </b>
          </a>
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            marginLeft: "42.55pt",
            textAlign: "justify",
            textIndent: "-42.55pt",
          }}
        >
          <span
            style={{
              fontSize: "12pt",
              lineHeight: "107%",
              fontFamily: '"Arial", sans-serif',
              color: "black",
            }}
          >
            18.1.
            <span style={{ font: '7pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontSize: "12pt",
              lineHeight: "107%",
              fontFamily: '"Arial", sans-serif',
              color: "black",
            }}
          >
            This Agreement contains the entire understanding of the Parties
            relating to the subject matter hereof and cancels and supersedes any
            prior understandings or agreements whether written or oral of the
            Parties. The User acknowledges that no representation or agreement
            not expressly contained in this Agreement has been made by the
            Foundation or any of its agents, employees or representatives.
          </span>
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            marginLeft: "42.55pt",
            textAlign: "justify",
            textIndent: "-42.55pt",
          }}
        >
          <span
            style={{
              fontSize: "12pt",
              lineHeight: "107%",
              fontFamily: '"Arial", sans-serif',
              color: "black",
            }}
          >
            18.2.
            <span style={{ font: '7pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontSize: "12pt",
              lineHeight: "107%",
              fontFamily: '"Arial", sans-serif',
              color: "black",
            }}
          >
            The parties to this Agreement will promptly execute and deliver any
            and all additional documents, instruments, notices, and other
            assurances, and will do any and all other acts and things reasonably
            necessary in connection with the performance of their respective
            obligations under this Agreement and to carry out the intent of the
            parties.
          </span>
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            marginLeft: "42.55pt",
            textAlign: "justify",
            textIndent: "-42.55pt",
          }}
        >
          <span
            style={{
              fontSize: "12pt",
              lineHeight: "107%",
              fontFamily: '"Arial", sans-serif',
              color: "black",
            }}
          >
            18.3.
            <span style={{ font: '7pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontSize: "12pt",
              lineHeight: "107%",
              fontFamily: '"Arial", sans-serif',
              color: "black",
            }}
          >
            Each of Foundation and User represents and warrants to the other
            party hereto that each has the capacity and authority to enter into
            this Agreement and that, upon its execution and delivery, the
            Agreement is a valid and enforceable agreement, binding upon such
            person or entity as permitted herein and under applicable law.
            Further, each hereby acknowledges and agrees that he/she/it has had
            the opportunity to consult with his/her/its own counsel prior to
            entering into and delivering this Agreement and has read and
            understands the terms and conditions set forth herein.
          </span>
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            marginLeft: "42.55pt",
            textAlign: "justify",
            textIndent: "-42.55pt",
          }}
        >
          <span
            style={{
              fontSize: "12pt",
              lineHeight: "107%",
              fontFamily: '"Arial", sans-serif',
              color: "black",
            }}
          >
            18.4.
            <span style={{ font: '7pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontSize: "12pt",
              lineHeight: "107%",
              fontFamily: '"Arial", sans-serif',
              color: "black",
            }}
          >
            Except as otherwise provided in the Agreement, if any provision of
            the Agreement is held to be invalid, void or for any reason
            unenforceable, such provision shall be struck out and shall not
            affect the validity and enforceability of the remaining provisions.
          </span>
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            marginLeft: "42.55pt",
            textAlign: "justify",
            textIndent: "-42.55pt",
          }}
        >
          <span
            style={{
              fontSize: "12pt",
              lineHeight: "107%",
              fontFamily: '"Arial", sans-serif',
              color: "black",
            }}
          >
            18.5.
            <span style={{ font: '7pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontSize: "12pt",
              lineHeight: "107%",
              fontFamily: '"Arial", sans-serif',
              color: "black",
            }}
          >
            In its sole discretion, the Foundation may assign the Agreement, and
            in such event, the Foundation will post notice on the Website.
          </span>
        </p>
        <p
          className="MsoListParagraphCxSpMiddle"
          style={{
            marginLeft: "42.55pt",
            textAlign: "justify",
            textIndent: "-42.55pt",
          }}
        >
          <span
            style={{
              fontSize: "12pt",
              lineHeight: "107%",
              fontFamily: '"Arial", sans-serif',
              color: "black",
            }}
          >
            18.6.
            <span style={{ font: '7pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontSize: "12pt",
              lineHeight: "107%",
              fontFamily: '"Arial", sans-serif',
              color: "black",
            }}
          >
            The Foundation's failure to act with respect to a breach by the User
            or others does not waive its right to act with respect to subsequent
            or similar breaches. The Foundation does not guarantee it will take
            action against all breaches of this Agreement.
          </span>
        </p>
        <p
          className="MsoListParagraphCxSpLast"
          style={{
            marginLeft: "42.55pt",
            textAlign: "justify",
            textIndent: "-42.55pt",
          }}
        >
          <span
            style={{
              fontSize: "12pt",
              lineHeight: "107%",
              fontFamily: '"Arial", sans-serif',
              color: "black",
            }}
          >
            18.7.
            <span style={{ font: '7pt "Times New Roman"' }}>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </span>
          <span
            style={{
              fontSize: "12pt",
              lineHeight: "107%",
              fontFamily: '"Arial", sans-serif',
              color: "black",
            }}
          >
            This Agreement has been executed by the Parties in English language
            and its text is posted on the Website at a location accessible to
            the User.
          </span>
        </p>
      </div>
        </div>
      </div>
     
    </div>
  );
}
